import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import {Clear, Search} from "components/Icons";
import Select from "components/Inputs/Select/CustomSelect";
import Loader from "components/Loader";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {APIEndPoints} from "globals/constants/endPoints";
import strings from "globals/constants/strings.json";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {CandidateResponse} from "globals/types/APIResponses";
import {debounce} from "lodash";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Spinner} from "react-activity";
import UserTable from "./table/UserTable";
import tableStyle from "./tableStyle.module.scss";

const scoreFilterOptions = [
  {
    label: "0-30%",
    value: "0-30",
    id: 1
  },
  {
    label: "30-50%",
    value: "30-50",
    id: 2
  },
  {
    label: "50-70%",
    value: "50-70",
    id: 3
  },
  {
    label: ">70",
    value: "70-100",
    id: 4
  }
];

const feedbackFilterOptions = [
  {
    label: "Feedback available",
    value: true
  },
  {
    label: "Feedback unavailable",
    value: false
  }
];

const statusFilterOptions = [
  {
    label: "Completed",
    value: "completed"
  },
  {
    label: "Incomplete",
    value: "incomplete"
  }
];

const Candidate: React.FC = () => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const {loading, setLoading} = useAppCommonDataProvider();
  const eventTracker = postGoogleAnalyticsEvent("Candidate");

  type tableRow = {
    id: string;
    candidateName: string;
    email: string;
    interviewStatus: string;
    candidate_id: number;
    reportCard: string;
    score: number;
    feedback: string;
  };

  const column = [
    {
      Header: "Candidate Name",
      accessor: "candidateName"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Interview Status",
      accessor: "interviewStatus"
    },
    {
      Header: "Report Card",
      accessor: "reportCard"
    },
    {
      Header: "Candidate Score",
      accessor: "score"
    },
    {
      Header: "Feedback",
      accessor: "feedback"
    }
  ];

  const [candidatesRow, setcandidatesRow] = useState<tableRow[] | []>([]);
  const [isFetching, setisFetching] = useState<boolean>(true);
  const [showLoadMore, setshowLoadMore] = useState(false);
  const [scoreFilter, setScoreFilter] = useState<string>("");
  const [searchKey, setSearchKey] = useState<string>("");
  const [feedbackKey, setFeedbackKey] = useState<boolean | null>(null);
  const [statusKey, setStatusKey] = useState("");

  const currentPage = useRef<number>(1);

  const filter = `?score=${scoreFilter.length ? scoreFilter : "0-100"}${
    feedbackKey !== null ? `&is_feedback_present=${feedbackKey}` : ""
  }${statusKey !== "" ? `&status=${statusKey}` : ""}${
    searchKey !== "" ? `&key=${searchKey}` : ""
  }`;

  useEffect(() => {
    fetchCandidates();
  }, [scoreFilter, searchKey, feedbackKey, statusKey]);

  const formateCandidates = (res: CandidateResponse) => {
    let formattedData: tableRow[] = [];
    res?.data?.data?.forEach((ele: any) => {
      ele?.candidate?.assessments?.sort((a: any, b: any) => b?.id - a?.id);

      const {status, score, video_url} = ele.candidate.assessments[0];

      formattedData.push({
        id: ele?.id?.toString(),
        candidateName: `${ele!?.first_name} ${ele!?.last_name}`,
        email: ele!?.email,
        score: Number(score),
        candidate_id: ele?.candidate?.id,
        interviewStatus:
          status === 5 || status === 14 ? "Completed" : "Started/In progress",
        reportCard: `${
          process.env.REACT_APP_DOMAIN
        }/download_reportcard?assessmentId=${
          ele?.candidate?.assessments?.[0]?.ai_assessment_id
        }&email=${encodeURIComponent(ele!?.email)}`,
        feedback: video_url
      });
    });

    res?.data?.meta?.current_page === 1
      ? setcandidatesRow([...formattedData])
      : setcandidatesRow([...candidatesRow, ...formattedData]);

    if (currentPage.current === res?.data?.meta?.last_page)
      setshowLoadMore(false);
    else setshowLoadMore(true);
  };

  const fetchCandidates = async () => {
    await axiosGetModule(
      APIEndPoints.CANDIDATE_WITH_DETAILS_NODE +
        `${filter}&page=${currentPage.current}`
    )
      .then((res: CandidateResponse) => formateCandidates(res))
      .catch((err) => console.log(err))
      .finally(() =>
        setTimeout(() => {
          setisFetching(false);
          setLoading?.(false);
        }, 300)
      );
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setSearchKey(value);
    }, 500),
    []
  );

  const clearFilters = () => {
    if (!candidatesRow.length) {
      if (searchInputRef.current !== null) searchInputRef.current.value = "";
      setSearchKey("");
      setScoreFilter("");
      setFeedbackKey(false);
      setStatusKey("");
    }
  };

  if (isFetching) return <Loader />;

  return (
    <div>
      <SizedBox height="30px" />
      <div>
        <p className="text-3xl font-bold text-monoGrey-800">
          {strings.candidate.heading}
        </p>
      </div>

      <div className="flex items-center justify-between mt-4">
        <div className="w-3/4 flex items-center">
          <div className="flex items-center border border-gray-300 p-2 rounded w-30">
            <p className="font-bold border-r-1px border-r-gray-300 mr-2 text-sm w-35">
              Filter by score
            </p>
            <Select
              options={scoreFilterOptions}
              onChange={({value}: {value: string}) => setScoreFilter(value)}
              placeholder="Select a score"
              customStyles={{
                control: (base: any) => ({
                  ...base,
                  border: "none",
                  width: "100%"
                }),
                container: (base: any) => ({
                  ...base,
                  width: "65%"
                })
              }}
            />
          </div>

          <div className="w-1/4 ml-6">
            <Select
              options={feedbackFilterOptions}
              onChange={({value}: {value: boolean}) => setFeedbackKey(value)}
              placeholder="Filter by feedback"
              customStyles={{
                control: (base: any) => ({
                  ...base,
                  border: "1px solid rgb(209, 213, 219)",
                  width: "100%"
                }),
                container: (base: any) => ({
                  ...base,
                  width: "100%"
                })
              }}
            />
          </div>

          <div className="w-1/5 ml-6">
            <Select
              options={statusFilterOptions}
              onChange={({value}: {value: string}) => setStatusKey(value)}
              placeholder="Filter by status"
              customStyles={{
                control: (base: any) => ({
                  ...base,
                  border: "1px solid rgb(209, 213, 219)",
                  width: "100%"
                }),
                container: (base: any) => ({
                  ...base,
                  width: "100%"
                })
              }}
            />
          </div>
        </div>
        <div className="flex relative h-11 justify-start w-1/4">
          <input
            type="text"
            className="border bg-white-800 border-white-200 rounded-md p-3 flex pr-12 outline-none w-90"
            placeholder="Search by candidate name"
            onChange={(e) => debounceSearch(e.target.value)}
            ref={searchInputRef}
          />
          <Search
            size={20}
            color={AppColors.PRIMARYCOLOR}
            className="relative top-3 right-8"
          />
        </div>
      </div>

      <SizedBox height="30px" />
      <div>
        <div className="flex justify-between w-100 items-center ">
          <div className="flex w-6/12 items-center justify-center"></div>
        </div>
        {candidatesRow.length ? (
          <>
            <SizedBox height="20px" />
            <div className={tableStyle.candidate__dashboard__body}>
              <UserTable column={column} row={candidatesRow} />
            </div>
          </>
        ) : (
          <div className="flex justify-center w-full flex-col items-center">
            <p className="font-semibold text-lg text-lightBlack-500 text-center mt-8">
              No Candidate found
            </p>
            <SizedBox height="1rem" />
            <p className="text-xl">
              {strings.assessment_test.no_templates_after_filtering}
            </p>
            <SizedBox height="1rem" />
            <button
              className="bg-primary text-white-900 px-10 py-2  w-fit rounded-lg flex items-center justify-center"
              onClick={clearFilters}
            >
              <Clear color={AppColors.WHITE} size={20} />
              <p className="ml-1 font-medium">
                {strings.candidate.no_candidate_reset}
              </p>
            </button>
          </div>
        )}

        {showLoadMore && (
          <div className="flex flex-col items-center">
            <ViewButton
              invert
              name={loading ? <Spinner size={15} /> : strings.more}
              isDisabled={loading ? true : false}
              onClick={() => {
                currentPage.current += 1;
                // fetchCandidates("");
              }}
              style={{paddingInline: "50px"}}
            />
            <SizedBox height="20px" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Candidate;
