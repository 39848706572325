import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {CountDownTimer} from "components/Timer";
import {useEffect, useState} from "react";
import styles from "./styles.module.css";

interface HeaderProps {
  title: string;
  isDarkMode?: boolean;
  showTimer?: boolean;
  initialTime?: number;
  handleTimerChange?: (remaining: number) => void;
  showTabSwitches?: boolean;
}
const InterviewHeader = ({
  title,
  isDarkMode = false,
  showTimer,
  initialTime,
  handleTimerChange,
  showTabSwitches
}: HeaderProps) => {
  const lightLogo =
    "https://images.ctfassets.net/7oc31naqqojs/19IuRVsEWksBeu3EtMHkuL/86ca2c966980d64c0c4869aa03f1a312/Frame_1686552542.png";
  const darkLogo =
    "https://images.ctfassets.net/7oc31naqqojs/wivo2YZ5aefPWwjqcNIXs/b945c193c56ad798c273f83a3ce2af34/Frame_1686552542__1_.png";

  const {interview_details} = useAppCommonDataProvider();
  const {data} = interview_details || {};

  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    if (initialTime) {
      setRemainingTime(initialTime);
    }
  }, [initialTime]);

  return (
    <div className={styles.main_wrapper}>
      <div>
        <img src={isDarkMode ? darkLogo : lightLogo} className={styles.logo} />
      </div>

      <p className={`${isDarkMode && "text-white-900"} ${styles.title}`}>
        {title}
      </p>

      <div className="flex flex-col items-center">
        {showTimer && initialTime ? (
          <div
            className={`py-2 px-4 rounded-[45px] text-white-900 max-w-24 ${
              remainingTime
                ? remainingTime > 180
                  ? "bg-[#28af3a]"
                  : "bg-[#f63333]"
                : "transparent"
            }`}
          >
            <CountDownTimer
              time={initialTime}
              sx={{fontWeight: "bold", color: "white"}}
              returnUpdatedValue={(remaining) => handleTimerChange?.(remaining)}
            />
          </div>
        ) : null}

        {showTabSwitches &&
          data?.assessment?.total_tab_switches &&
          data.assessment.total_tab_switches > 0 && (
            <p
              className={`${
                isDarkMode ? "text-white-900" : "text-lightBlack-900"
              } text-sm font-bold mt-2`}
            >{`You have ${
              5 - data.assessment.total_tab_switches
            } tab switches remaining`}</p>
          )}
      </div>
    </div>
  );
};

export default InterviewHeader;
