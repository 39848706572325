import {Document, Page, PDFViewer, Text, View} from "@react-pdf/renderer";
import {SecondRoundQuestion} from "globals/types/globalTypes";
import {useEffect, useState} from "react";

const QNA = () => {
  const [questions, setQuestions] = useState<SecondRoundQuestion[]>([]);

  useEffect(() => {
    setQuestions(
      JSON.parse(localStorage.getItem("qna")!) as SecondRoundQuestion[]
    );
    // localStorage.removeItem("qna");
  }, []);

  return (
    <PDFViewer showToolbar={true} style={{height: "100vh", width: "100vw"}}>
      <Document>
        <Page
          size={{width: 595.28, height: 1500}}
          style={{fontFamily: "Urbanist", width: 595.28, height: 1500}}
        >
          <View style={{padding: 30}}>
            {questions.map(({question, question_answer}, index) => (
              <View style={{marginBottom: 15}}>
                <View style={{flexDirection: "row"}}>
                  <Text style={{fontWeight: "bold", fontSize: 11}}>{`${
                    index + 1
                  }. `}</Text>
                  <Text style={{fontWeight: "bold", fontSize: 11}}>
                    {question}
                  </Text>
                </View>
                <Text
                  style={{marginLeft: 15, fontSize: 12, marginTop: 5}}
                >{`- ${question_answer}`}</Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default QNA;
