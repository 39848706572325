import PageLoader from "components/PageLoader";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {APIEndPoints} from "globals/constants/endPoints";
import {RouteLinks} from "globals/constants/routeLinks";
import {decodeEncodedAPIResponse} from "globals/helpers";
import {AssessmentDataResponse} from "globals/types/globalTypes";
import axiosPostModule from "network/Post/AxiosPostModule";
import React, {useEffect} from "react";
import cookie from "react-cookies";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";

export const CandidateLogin: React.FC = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();

  const payload = JSON.parse(`${queryParams.get("payload")!}`);
  const {data} = decodeEncodedAPIResponse(payload, {});

  useEffect(() => {
    const {name, lastname, email, password} = data;
    axiosPostModule(`${APIEndPoints.CANDIDATE_LOGIN_NODE}?isRoundTwo=true`, {
      first_name: name.trim(),
      last_name: lastname.trim(),
      email: email.trim(),
      password: password.trim()
    })
      .then(({data: {interview, Token}}: AssessmentDataResponse) => {
        if (interview && !interview.length) {
          toast.error("There are no assessments available for you");
          return setTimeout(() => {
            navigate("/");
          }, 2000);
        }
        localStorage.setItem("candidateToken", Token);
        cookie.save(COOKIESKEYS.CANDIDATE_TOKEN, Token, {
          path: "/"
        });
        localStorage.setItem(
          COOKIESKEYS.ROUND_TWO_QUESTIONS,
          JSON.stringify(interview![0].assessment_round_two_questions)
        );

        const assessmentId = interview![0].id;
        navigate(
          `/${RouteLinks.Interview_Preview}?assessment=${assessmentId}&token=${Token}`
        );
      })
      .catch((e) => {
        if (e.data.code === 422) {
          toast.error(
            "This link is no longer valid. Please check for a new link or contact someone"
          );
          navigate("/");
        }
      });
  }, []);

  return <PageLoader />;
};
