import {base64Toblob} from "globals/helpers";
import {useUploadScreenshot} from "hooks/interview/useUploadScreenshot";
import React, {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import WebCam from "react-webcam";

interface IProps {
  videoConstraints?: boolean | MediaTrackConstraints | undefined;
  className?: string;
  captureScreenshot?: boolean;
  audio?: boolean;
  pingedScreenshots?: boolean;
  pingInterval?: number;
  onCaptureScreenshot?: (image: string) => void;
  screenshotFormat?: "image/png" | "image/webp" | "image/jpeg";
  isCentered?: boolean;
}

export const Webcam: React.FC<IProps> = ({
  videoConstraints,
  className,
  captureScreenshot,
  audio = false,
  pingedScreenshots,
  pingInterval,
  screenshotFormat,
  isCentered = false,
  onCaptureScreenshot
}) => {
  const defaultConstraints: MediaTrackConstraints = {
    width: 1200,
    height: 730,
    facingMode: "user"
  };

  const classList =
    className !== undefined ? `call__video ${className}` : "call__transform";

  const buttonRef = useRef<HTMLButtonElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const timerId = useRef<NodeJS.Timer | undefined>(undefined);
  const pingedTimerId = useRef<NodeJS.Timer | undefined>(undefined);
  const webcamRef = useRef<WebCam>(null);

  const {assessmentId} = useParams();

  const {mutateAsync: uploadScreenshot} = useUploadScreenshot({assessmentId});

  async function uploadWebcamScreenshot(uri: string) {
    const data = new FormData();
    const screenshot = await base64Toblob(uri);
    const image = new File([screenshot], "screenshot.png", {
      type: screenshot.type
    });
    data.append("assessment_screenshot", image);
    uploadScreenshot(data);
  }

  function takePicture() {
    const context = canvasRef.current?.getContext("2d");
    if (canvasRef.current !== null) {
      let width = 0,
        height = 0;
      if (videoConstraints !== undefined) {
        //@ts-ignore
        const {width: constraintsWidth, height: constraintsHeight} =
          videoConstraints.valueOf();
        width = constraintsWidth;
        height = constraintsHeight;
      } else {
        //@ts-ignore
        width = defaultConstraints.width;
        //@ts-ignore
        height = defaultConstraints.height;
      }
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      const video = document.getElementById("video");
      //@ts-ignore
      context?.drawImage(video, 0, 0, width, height);
      const data = canvasRef.current.toDataURL("image/png");
      uploadWebcamScreenshot(data);
    }
  }

  useEffect(() => {
    if (captureScreenshot !== undefined && captureScreenshot === true)
      timerId.current = setInterval(() => buttonRef.current?.click(), 40000);

    return () => {
      if (captureScreenshot !== undefined && captureScreenshot === true)
        clearInterval(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (pingedScreenshots) {
      pingedTimerId.current = setInterval(() => {
        const image = webcamRef.current?.getScreenshot();
        onCaptureScreenshot && onCaptureScreenshot(image!);
      }, pingInterval);
    }

    if (!pingedScreenshots) {
      clearInterval(pingedTimerId.current);
    }

    return () => {
      clearInterval(pingedTimerId.current);
    };
  }, [pingedScreenshots]);

  return (
    <div className={`w-full h-full ${isCentered ? "flex justify-center" : ""}`}>
      <WebCam
        videoConstraints={
          videoConstraints ? videoConstraints : defaultConstraints
        }
        className={classList}
        id="video"
        audio={audio}
        ref={webcamRef}
        screenshotFormat={screenshotFormat ?? "image/webp"}
      />
      <button onClick={takePicture} ref={buttonRef} className="hidden">
        Take photo
      </button>
      <canvas ref={canvasRef} style={{display: "none"}}></canvas>
    </div>
  );
};
