import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import {APIEndPoints} from "globals/constants/endPoints";
import {postMixPanelEvent, staticHeaders} from "globals/helpers";

export function useUploadScreenshot({
  assessmentId,
  isResume
}: {
  assessmentId?: string;
  isResume?: boolean;
}): UseMutationResult<
  AxiosResponse<{data: {path: string}}>,
  unknown,
  FormData
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["uploadScreenshot"],
    mutationFn: async (data: FormData) => {
      return axios
        .post(APIEndPoints.SEND_SCREENSHOT(assessmentId, isResume), data, {
          headers: {
            ...staticHeaders(),
            Accept: "application/json"
          }
        })
        .then((res) => {
          queryClient.invalidateQueries(["uploadScreenshot"]);
          return res;
        });
    },
    onError: (e) =>
      postMixPanelEvent("Error uploading screenshot or resume", {
        source: "frontend",
        error: e
      })
  });
}
