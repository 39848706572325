import {Theme} from "@mui/material";
import {SystemStyleObject} from "@mui/system";
import {MicroCopyValues} from "globals/constants/microcopies";
import {Moment} from "moment";
import {CSSProperties} from "react";

export type NullishString = string | null;
export type NA = "N.A.";
export type ButtonSubmit = "submit" | "button" | "reset" | undefined;
export type DateType = Date | null | undefined;
export type AssessmentFilterSource = "filter" | "default";
export type NewCandidateFields =
  | "firstName"
  | "lastName"
  | "email"
  | "experience"
  | "source"
  | "resume";
export type TimeStamp = Date | Moment | null;
export type ZeroOrOne = 0 | 1;

export enum RecorderStatus {
  MEDIA_ABORTED = "media_aborted",
  PERMISSION_DENIED = "permission_denied",
  NO_SPECIFIED_MEDIA_FOUND = "no_specified_media_found",
  MEDIA_IN_USE = "media_in_use",
  INVALID_MEDIA_CONSTRAINTS = "invalid_media_constraints",
  NO_CONSTRAINTS = "no_constraints",
  RECORDER_ERROR = "recorder_error",
  IDLE = "idle",
  ACQUIRING_MEDIA = "acquiring_media",
  RECORDING = "recording",
  STOPPING = "stopping",
  STOPPED = "stopped"
}

export interface NewCandidateExperience {
  projects: Array<{
    project_name: string;
    project_description: string;
  }>;
}

export interface Routes {
  name: string;
  link: string;
  logo?: string;
  Icon?: any;
}

export interface SelectOption {
  label: string;
  value: any;
  questions_count?: any;
}

export type DatePickerBox =
  | boolean
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>);

export type CSS = CSSProperties | undefined;

export interface PaginationLinks {
  first: URL | string;
  last: URL | string;
  next: any;
  prev: any;
}

export interface CandidateDetails_NewAssessment {
  firstName: string;
  lastName: string;
  email: string;
  experience: number;
  source?: string;
  resume?: any;
}

export interface AssessmentSkill {
  id: number;
  skill: string;
  laravel_through_key?: number;
  label?: string;
  value?: number;
}

export type SecondRoundQuestionType =
  | "behavioral_analysis"
  | "coding_analysis"
  | "Technical_analysis"
  | "greetings";

export interface SecondRoundQuestion {
  overall_type: SecondRoundQuestionType;
  assessmentId: number;
  id: number;
  question: string;
  ai_question_id: string;
  audio_url: string;
  lipsync_data: string | null;
  animation: string | null;
  facialExpression: string | null;
  question_answer: string | null;
  skill_id?: number;
  isFollowUpQuestion?: boolean;
  options?: string;
  is_answered?: boolean;
  assessment_behaviour_question_options?: any[];
  sample_input?: string;
  expected_output?: string;
}

export interface AssessmentData {
  assessment_duration: number;
  assessment_level: number;
  ai_assessment_id?: string;
  main_question_id?: string;
  assessment_points_scored: string | null;
  assessment_round_two_questions?: Array<SecondRoundQuestion>;
  assessment_total_points: string | null;
  assessment_template_id: number;
  assessment_url: URL | string;
  candidate: {
    experience_level: number;
    id: number;
    resume_file_id: number | null;
    number_of_questions: string;
    source: string;
    user: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
    };
    user_id: number;
  };
  candidate_id: number;
  created_at: string;
  created_by: number;
  deleted_at: any;
  experience_duration: string;
  hold_reason: any;
  // added
  include_intro: boolean;
  include_round_one: boolean;
  //
  id: number;
  include_organization_questions: number;
  ip_address: string | null;
  ip_change_count: number | null;
  location: string | null;
  name: string;
  number_of_questions: number | null;
  organization: {
    id: number;
    logo: URL | string;
    name: string;
  };
  organization_id: number;
  scheduled_date: string;
  skills: AssessmentSkill[];
  status: number;
  time_remaining: null | number;
  time_taken: null | number;
  total_tab_switches: number | null;
  updated_at: string;
  updated_by: number;
}

export interface AssessmentDataResponse {
  data: {
    Token: string;
    check: string;
    assesments: AssessmentData[];
    interview?: AssessmentData[];
  };
}

export interface AssessmentMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: URL;
  links: Array<{
    url: URL | string | null;
    label: string | URL;
    active: boolean | null | undefined;
  }>;
  per_page: number;
  to: number;
  total: number;
}

export interface CreateAssessmentInput {
  organization_id: number;
  assessment_template_id: number;
  skill_ids: number[];
  scheduled_date: string;
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_email: string;
  candidate_experience_level: number;
  candidate_source: string | undefined;
  candidate_resume: File | undefined;
}

export interface AssessmentTemplateData {
  id: number;
  organization_id: number;
  name: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null | undefined;
  organization: {
    id: number;
    name: string;
    logo: URL | string;
  };
  skills: AssessmentSkill[];
  candidate: {
    experience_level: number;
    id: number;
    resume_file_id: number | null;
    source: string;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
    user_id: number;
  };
  assessment_template_id?: number;
  experience_duration: string;
  number_of_questions: string | null;
}

export interface CreateAssessmentTemplate {
  organization_id: number;
  name: string;
  skill_ids: Array<number>;
}

type questionOptions = {
  id: number;
  question_id: number;
  option: string;
  option_points: number;
  is_correct_option: number;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | any;
};

export interface GetAllQuestionData {
  has_multiple_answers: boolean | null;
  is_always_included: number;
  is_paid_question: number;
  id: number;
  organization_id: number;
  skill_id: number;
  question_file_id: string | null;
  question_type: number;
  question_level: number;
  question: string;
  question_points: number;
  question_duration: number;
  shuffle_options: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | any;
  skill: {
    id: number;
    skill: string;
  };
  created_by: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    email_verified_at: string;
    phone: string | any;
    phone_verified_at: string | any;
    created_by: 1;
    updated_by: 1;
  };
  options?: questionOptions[];
  creator?: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export interface GetAllQuestionDataNode {
  created_at: string;
  created_by: any;
  deletedAt: null;
  id: number;
  is_always_included: number;
  is_paid_question: number;
  organization_id: number;
  question: string;
  question_duration: number;
  question_level: number;
  options?: [
    {
      option_id: number;
      is_correct_option: number;
      option: string;
    }
  ];
  question_options?: [
    {
      is_correct_option: number;
      option: string;
    }
  ];
  question_points: number;
  question_type: number;
  shuffle_options: number;
  skill: {
    skill: string;
  };

  skill_id: number;
  updated_at: string;
  updated_by: number;
  user: {
    email: string;
    first_name: string;
    last_name: string;
  };
}

export interface SkillsData {
  id: number;
  skill: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | any;
  question_count?: {
    non_organization_question_level_1: number;
    organization_question_level_1: number;
    non_organization_question_level_2: number;
    organization_question_level_2: number;
    non_organization_question_level_3: number;
    organization_question_level_3: number;
  };
}

export interface CreateQuestionData {
  id: number;
  organization_id: number;
  skill_id: number;
  question_file_id: string | any;
  question_type: number;
  question_level: number;
  question: string;
  question_points: number;
  question_duration: number;
  shuffle_options: number;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | any;
}

export interface CallDescription {
  sdp?: string;
  type: RTCSdpType;
}

export enum CallStatus {
  CALLING = "CALLING",
  ANSWERED = "ANSWERED",
  CONNECTING = "CONNECTING"
}

export interface InterviewCandidateDetails {
  communication_points_scored: string;
  created_at: TimeStamp;
  created_by: number;
  deleted_at: TimeStamp;
  experience_level: number;
  id: number;
  intro_video_file_id: number | null;
  resume_file_id: number | null;
  source: string | null;
  updated_at: TimeStamp;
  updated_by: number;
  user_id: number;
  user: {
    first_name: string;
    last_name: string;
    email: string;
  };
}

export interface InterviewAssessmentDetails {
  accuracy: number | null;
  ai_assessment_id: string;
  assembly_ai_id: NullishString;
  assessment_duration: number;
  behavior_duration: number;
  coding_duration: number;
  assessment_level: number;
  assessment_points_scored: null | number;
  assessment_questions: SecondRoundQuestion[];
  assessment_round_two_questions: SecondRoundQuestion[];
  assessment_template?: {
    created_at: TimeStamp;
    created_by: number;
    deletedAt: TimeStamp;
    id: number;
    name: string;
    organization_id: number;
    updated_at: TimeStamp;
    updated_by: number;
  } | null;
  assessment_template_id: number;
  assessment_total_points: string | null;
  assessment_url: URL | string;
  candidate: InterviewCandidateDetails;
  candidate_id: number;
  created_at: TimeStamp;
  created_by: number;
  deleted_at: TimeStamp;
  id: number;
  ip_address: string;
  ip_change_count: string | null;
  include_organization_questions: number;

  // new keys for contractual hiring
  is_contractual: boolean;
  include_intro: boolean;
  include_round_one: boolean;
  include_round_two: boolean;
  // end of new keys

  //one new key added for skills
  assessment_skills: assementSkills[];
  skills: assementSkills[];
  name: string;
  organization_id: number;
  scheduled_date: TimeStamp;
  resume_url: string;
  status: number;
  screenshots: CandidateDetailsScreenshots[];
  time_remaining: number | null;
  time_taken: number | null;
  template: {
    created_at: TimeStamp;
    created_by: number;
    deleted_at: TimeStamp;
    id: number;
    name: string;
    organization_id: number;
    updated_at: TimeStamp;
    updated_by: number;
  } | null;
  updated_at: Date;
  updated_by: number;
  main_question_id?: string;
  total_tab_switches: number;
  from_sourcebae?: boolean | null;
}

export interface InterviewQuestionOption {
  id: number;
  option: string;
  question_id: number;
}

export interface QuestionOption {
  id: number;
  is_correct_option: number;
  option: string;
  question_id: number;
}

export interface InterviewQuestion {
  assessment_id: number;
  created_at: null | Date;
  created_by: number;
  deletedAt: null | Date;
  id: number;
  question: {
    id: string;
    is_always_included: number;
    is_paid_question: number;
    question: string;
    question_duration: number;
    question_level: number;
    question_options: QuestionOption[];
    question_type: number;
    skill_id: number;
  };
  question_id: number;
  skill: {id: number; skill: string};
  status: number;
  updated_at: string | Date;
}

export interface assementSkills {
  assessment_id: number;
  created_at: null | Date;
  created_by: number;
  deletedAt: null | Date;
  id: number;
  skill: {id: number; skill: string};
}

export interface CandidateAssesments {
  id: number;
  organization_id: number;
  assessment_template_id: number;
  candidate_id: number;
  name: string;
  assessment_url: string;
  scheduled_date: string;
  assessment_level: number;
  assessment_duration: number;
  assessment_score: null;
  status: number;
  candidate_hiring_status: number;
  include_organization_questions: number;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}
export interface CandidateData {
  id: number;
  user_id: number;
  resume_file_id: number;
  intro_video_file_id: string | any;
  experience_level: number;
  source: string;
  communication_score: string | any;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | any;
  first_name: string;
  last_name: string;
  email: string;
  email_verified_at: string;
  phone: string | number;
  phone_verified_at: string;

  resume?: {
    id: number;
    title: string;
    name: string;
    disk: string;
    path: string;
    extension: string;
    mime: string;
    size: number;
    created_by: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | any;
  };
  assessments: CandidateAssesments[];
}

export interface CandidateDetailsData {
  id: number;
  user_id: number;
  resume_file_id: number;
  intro_video_file_id: null;
  experience_level: number;
  source: string;
  communication_score: string;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  assessments: CandidateAssesments[];
  resume: {
    id: number;
    title: string;
    name: string;
    disk: string;
    path: string;
    extension: string;
    mime: string;
    size: number;
    created_by: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  };
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    email_verified_at: null;
    phone: null;
    phone_verified_at: null;
    created_by?: number;
    updated_by?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: null;
  };
}

export interface UserDetailsCookie {
  user: {
    id: 2;
    first_name: string;
    last_name: string;
    email: string;
    teams: [
      {
        id: number;
      }
    ];
  };
  role_id: number;
  organization_id: number;
  team_id: number;
}

export interface DashboardData {
  data: {
    card_data: {
      total_candidate: number;
      total_candidate_hired: number;
      total_candidate_shortlisted: number;
      total_candidate_rejected: number;
      total_candidate_completed: number;
      cost_saved_by_you: number;
      time_saved_by_you: number;
      cost_saved_by_recruitbae: number;
      time_saved_by_recruitbae: number;
      recent_candidates: Array<{
        first_name: string;
        last_name: string;
        assessment_points_scored: string | null;
        assessment_total_points: string;
        id: number;
      }>;
    };
    events_data: {
      [key: string]: [
        {
          id: number;
          candidate_id: number;
          name: string;
          assessment_template_id: number;
          scheduled_date: string;
        }
      ];
    };
  };
}
export interface IntrodutionVideo {
  success: string;
  communication_score: string;
  created_at: Moment | Date | null;
  created_by: number;
  deleted_at: Moment | Date | null;
  experience_level: number;
  id: number;
  intro_video_file_id: number;
  resume_file_id: number | null;
  source: string;
  updated_at: Moment | Date | null;
  updated_by: number;
  user_id: number;
}

export interface InterviewInstructionsFailure {
  message: string;
  name: string;
  description: any;
}

export interface InterviewQuestionsFailure
  extends InterviewInstructionsFailure {}

export interface UploadCSV {
  data: any[];
}

export interface CandidateDetailsScreenshot {
  created_at: string;
  created_by: number;
  deleted_at: null | string;
  disk: string;
  extension: string;
  id: number;
  mime: string;
  name: string;
  path: string;
  size: string;
  title: string;
  updated_at: string;
  updated_by: number;
}

export interface CandidateDetailsScreenshots {
  assessment_id: number;
  created_at: string;
  created_by: number;
  deleted_at: null | string;
  file: CandidateDetailsScreenshot;
  file_id: number;
  id: number;
  updated_at: string;
  updated_by: number;
  path: string;
  title: string;
  extension: string;
}

export interface CandidateExperience {
  assessment_id: number;
  candidate_id: number;
  company_name: string;
  created_at: string;
  created_by: number;
  experience_description: string;
  id: number;
}

export interface CandidateProject {
  assessment_id: number;
  candidate_id: number;
  id: number;
  project_description: string;
  project_title: string;
}

export interface SignupPersonalDetailFormValues {
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  phone_no: string;
}

export interface SignupCompanyDetailFormValues {
  user_designation: string;
  company_name: string;
  website: string;
}
export interface SignupCreatePasswordFormValues {
  create_password: string;
  re_enter_password: string;
}

export interface Permissions {
  camera: boolean;
  microphone: boolean;
}

export enum DialogType {
  explanation = "explanation",
  systemDenied = "systemDenied",
  userDenied = "userDenied",
  trackError = "trackError"
}

export interface UserTableData {
  name: string | null;
  email: string | null;
  role: number | string | null;
  status: number | string | null;
  delete: string | null;
  user_id: string | null;
}

export interface UserDataState {
  first_name: string;
  last_name: string;
  email: string;
  role: number;
}
export interface Questions {
  id: number;
  question_id: number;
  correctAnswer: string;
  question: {
    id: number;
    question: string;
    question_option: {
      id: number;
      is_corect_option: number;
      option: string;
    };
  };
}

export interface SelectedOptions {
  assessment_id: number;
  id: number;
  question_id: number;
  question_option_id: number;
}

export interface CreateAssessmentValues {
  skills: SelectOption[];
  date: Moment | null;
  first_name: string;
  last_name: string;
  email: string;
  candidate_source: string;
  resume: File | null;
  years_of_experience: string;
  questions: string;
  project_info: any[];
  is_contractual: ZeroOrOne;
  include_intro?: ZeroOrOne;
  include_round_one?: ZeroOrOne;
  include_round_two?: ZeroOrOne;
  jd: string;
  codingQuestions: GetAllQuestionDataNode[];
}

export interface WorkExperienceFormValues {
  experience: string;
  number_of_questions: string;
  projectName: string;
  projectDescription: string;
  jd: string;
}

export interface CustomModalBaseProps {
  open: boolean;
  onClose: () => void;
}

export interface CreateAsseessmentFields {
  scheduled_date: string;
  candidate_name: string;
  candidate_last_name: string;
  email: string;
  experience_duration: string;
  skills_ids: string[];
  resume_url: string;
  responseURL: boolean;
  organization_id: string;
  assessment_name: string;
  job_description?: string;
}

export type CreateAssessmentCodingQuestion = Pick<
  GetAllQuestionDataNode,
  | "question"
  | "question_level"
  | "question_points"
  | "skill_id"
  | "question_duration"
  | "is_always_included"
  | "question_type"
> & {
  options: {
    is_correct_option: number;
    option: string;
  }[];
};
export interface CandidateResumeEducation {
  completion_date: string;
  course: string;
  id: number;
  organization: string;
}

export interface CandidateResumeExperience {
  company_name: string;
  designation: string;
  end_date: string;
  experience_description: string;
  id: number;
}

export interface CandidateResumePhoneNumber {
  phone_number: string;
}

export interface CandidateResumeSkills {
  id: number;
  skill_name: string;
}

export interface CandidateResumeData {
  behaviour_points_scored: NullishString;
  behaviour_total_points: NullishString;
  candidate_education_details: Array<CandidateResumeEducation>;
  candidate_experience_details: Array<CandidateResumeExperience>;
  candidate_phone_numbers: Array<CandidateResumePhoneNumber>;
  candidate_skill_details: Array<CandidateResumeSkills>;
}

export type Language = {
  id: number;
  name: string;
};

export type Microcopy = {
  key: string;
  value: string;
};

export type Constant = {
  title: string;
  value: string;
};

export type Site = {
  instructionsCollection: {
    items: Microcopy[];
  };

  microcopiesCollection: {
    items: Microcopy[];
  };

  constantsCollection: {
    items: Constant[];
  };
};

export type GetMicroCopy = (
  path: MicroCopyValues,
  variables?: Record<string, string>
) => string;

export type Chat = {
  isUserChat: boolean;
  chat: string;
};

export type FullScreenParameters = {
  didUserPrompt: boolean;
  showModal: boolean;
  changeCount: number;
};

export type SecondRoundFollowUpOrRealTimeQuestion = {
  assessment_id: number;
  ai_question_id: string;
  overall_type: SecondRoundQuestionType;
  isFollowUpQuestion: boolean;
  questionText: string;
  audio_file_path_mp3?: string;
  id?: number;
  options?: any[];
  isTechnicalQuestion?: boolean;
  isBehaviourQuestion?: boolean;
  assessment_behaviour_question_options?: any[];
  sample_input?: string;
  expected_output?: string;
};

export type RuntimeQuestion = {
  questionId: string;
  questionText: string;
  question_options: string[];
  questionAnswer: string;
  sampleInput?: any;
  expectedOutput?: any;
};
