import React from "react";
import {ThreeDots} from "react-loader-spinner";
import styles from "./Loader.module.css";

const Loader: React.FC<{message?: string; color?: string}> = ({
  message,
  color
}) => {
  return (
    <div className={styles.spinner_overlay}>
      <div className={styles.spinner_container}>
        <ThreeDots
          height={50}
          width={50}
          color={color ?? "black"}
          ariaLabel="loading"
        />
        {message && <h1 className={styles.message}>{message}</h1>}
      </div>
    </div>
  );
};

export default Loader;
