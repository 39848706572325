import {createEmptyCreateAssessmentValues} from "globals/helpers";
import {CreateAssessmentValues} from "globals/types/globalTypes";
import {createContext, useContext, useState} from "react";

export interface CreateAssessmentContextProps {
  createAssessmentValues: CreateAssessmentValues;
  setCreateAssessmentValues: (values: CreateAssessmentValues) => void;
}

export const CreateAssessmentContext =
  createContext<CreateAssessmentContextProps>({
    createAssessmentValues: createEmptyCreateAssessmentValues(),
    setCreateAssessmentValues: () => {}
  });

const CreateAssessmentProvider = (props: {children: React.ReactElement}) => {
  const [createAssessmentValues, setCreateAssessmentValues] = useState<
    CreateAssessmentContextProps["createAssessmentValues"]
  >(createEmptyCreateAssessmentValues());

  return (
    <CreateAssessmentContext.Provider
      value={{
        createAssessmentValues,
        setCreateAssessmentValues
      }}
    >
      {props.children}
    </CreateAssessmentContext.Provider>
  );
};

export default CreateAssessmentProvider;

export const useCreateAssessmentProvider = () =>
  useContext<CreateAssessmentContextProps>(CreateAssessmentContext);
