import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {useCreateAssessmentProvider} from "components/CreateAssessmentProvider";
import {RightArrow} from "components/Icons";
import DatePicker from "components/Pickers/DatePicker/DatePicker";
import otherStyles from "components/styles.module.scss";
import {AppColors} from "globals/appcolors";
import {APIEndPoints} from "globals/constants/endPoints";
import strings from "globals/constants/strings.json";
import {CSS, DatePickerBox, SkillsData} from "globals/types/globalTypes";
import moment from "moment";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "./style.module.scss";
interface Props {
  next: () => void;
}

const datePickerInputStyle: CSS = {
  height: 50,
  background: "transparent",
  width: "100%"
};

const datePickerContainerStyle: DatePickerBox = {
  background: AppColors.WHITE_800,
  paddingX: "10px",
  height: 50,
  borderRadius: "6px",
  border: `1px solid ${AppColors.WHITE_300}`
};

const skillsError = "Please select some skills";
const experienceError = "Please enter a valid experience";
const resumeError = "Please select a resume";

export const BasicAssessmentDetails: React.FC<Props> = ({next}) => {
  const animatedComponents = makeAnimated();
  const {assessment_details: reusable_assessment_details} =
    useAppCommonDataProvider();
  const {createAssessmentValues, setCreateAssessmentValues} =
    useCreateAssessmentProvider();

  const [skills, setskills] = useState<any>();
  const [errors, setErrors] = useState<string[]>([]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchSkills();
  }, []);

  const formatSkills = (list: SkillsData[]) => {
    let generatedFormat: Array<any> = [];
    list?.forEach((element) => {
      generatedFormat.push({
        label: element.skill,
        value: element.id
      });
    });
    setskills(generatedFormat);
  };

  const fetchSkills = async () => {
    await axiosGetModule(APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE)
      .then((res) => {
        formatSkills(res.data);
      })
      .catch((err) => console.log("fetchSkills err :", err));
  };

  const handleTagsChange = (newElements: any) => {
    setErrors([...errors].filter((e) => e !== skillsError));
    setCreateAssessmentValues?.({
      ...createAssessmentValues,
      skills: newElements
    });
  };

  const uploadResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };

  const handleExperienceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(+e.target.value)) {
      return;
    }
    setErrors((prev) => prev.filter((error) => error !== experienceError));
    setCreateAssessmentValues?.({
      ...createAssessmentValues,
      years_of_experience: e.target.value
    });
  };

  const handleNext = () => {
    if (createAssessmentValues.skills.length === 0) {
      setErrors([...errors, skillsError]);
      return;
    }
    if (!createAssessmentValues.years_of_experience) {
      return setErrors([...errors, experienceError]);
    }

    if (!createAssessmentValues.resume) {
      return setErrors([...errors, resumeError]);
    }

    next();
  };

  return (
    <React.Fragment>
      <div className="bg-white-900  py-8 rounded relative pt-0">
        <p className="font-bold text-2xl">
          {strings.create_assessment.basic_details}
        </p>
        <div className="mt-8">
          <p className="text-sm">{strings.create_assessment.tags}</p>
          <div className="mt-2">
            <Select
              isMulti
              isSearchable
              isClearable
              options={skills}
              onChange={handleTagsChange}
              closeMenuOnSelect={false}
              components={animatedComponents}
              backspaceRemovesValue
              value={createAssessmentValues.skills.map((tag) => tag) ?? []}
              defaultValue={createAssessmentValues.skills ?? []}
              theme={(theme: any) => ({
                ...theme,
                borderRadius: "6px",
                colors: {
                  ...theme.colors,
                  primary25: "#fafafa",
                  primary: AppColors.PRIMARYCOLOR
                }
              })}
              isDisabled={reusable_assessment_details ? true : false}
            />
          </div>
          {errors.includes(skillsError) && (
            <p className="text-red-500 text-xs">{skillsError}</p>
          )}
        </div>

        <div className="mt-8">
          <p className="text-sm">{strings.create_assessment.date}</p>
          <div className="mt-2">
            <DatePicker
              onChange={(newdate) => {
                setCreateAssessmentValues?.({
                  ...createAssessmentValues,
                  date: newdate
                });
              }}
              value={createAssessmentValues?.date}
              format={"DD-MM-YYYY"}
              customInputStyle={datePickerInputStyle}
              isCustomInput
              customContainerStyle={datePickerContainerStyle}
              minDate={moment()}
            />
          </div>
        </div>

        <div className="mt-8 relative">
          <p className="text-sm">
            {strings.new__candidate__experience.total_experience}
          </p>
          <input
            onChange={handleExperienceChange}
            value={createAssessmentValues.years_of_experience}
            className={`${otherStyles.input} !w-full`}
            placeholder="Experience in years"
            maxLength={2}
          />
          {errors.includes(experienceError) && (
            <p className="text-red-500 text-xs">{experienceError}</p>
          )}
        </div>

        <div className="mt-8">
          <p className="text-sm">{strings.create_assessment.resume}</p>
          <div
            className="mt-2 w-full border border-dotted flex items-center p-4 py-6 rounded"
            style={{borderColor: AppColors.BLACK_BORDER_LIGHT}}
          >
            <div className="flex flex-0.3 items-center justify-center">
              <button
                style={{background: AppColors.LIGHT_BG_BLUE}}
                className="w-1/2 h-10 rounded text-blue-500 font-bold text-sm"
                onClick={uploadResume}
              >
                {strings.upload}
              </button>
            </div>
            <div className="flex flex-0.7 text-black-700 items-center text-sm">
              {createAssessmentValues?.resume?.name ??
                strings.create_assessment.no_resume}
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={({target: {files}}) => {
                  if (files && files.length > 0) {
                    setErrors((prev) =>
                      prev.filter((error) => error !== resumeError)
                    );
                    setCreateAssessmentValues?.({
                      ...createAssessmentValues,
                      resume: files[0]
                    });
                  }
                }}
                style={{display: "none"}}
              />
            </div>
          </div>
          {errors.includes(resumeError) && (
            <p className="text-red-500 text-xs">{resumeError}</p>
          )}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <div className={styles.next_btn_wrap} onClick={handleNext}>
          <div className={styles.next_btn_before}></div>
          <button className={styles.next_button}>Next</button>
          <RightArrow className={styles.icon} size={20} />
        </div>
      </div>
    </React.Fragment>
  );
};
