import DashboardIcon from "@mui/icons-material/DashboardRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import {RouteLinks} from "globals/constants/routeLinks";
import {RouteNames} from "globals/constants/routeNames";
import {Routes} from "globals/types/globalTypes";

export const routes: Array<Routes> = [
  {
    name: RouteNames.Dashboard,
    link: `/${RouteLinks.Dashboard}`,
    Icon: DashboardIcon
  },
  {
    name: RouteNames.Assessments,
    link: `/${RouteLinks.Assessment_Page}`,
    Icon: DescriptionOutlinedIcon
  },
  {
    name: RouteNames.Candidate,
    link: `/${RouteLinks.Candidate}`,
    Icon: PeopleOutlineRoundedIcon
    // logo: CandidateIcon
  }
];

export const specialRoutes = [
  "/login",
  "/",
  "/register",
  "/resetpassword",
  "/passwordchange",
  "/interview",
  "/reset-password",
  "/college-landing-page",
  `/${RouteLinks.Candidate_Login}`,
  `/${RouteLinks.Interview__Questions}`,
  `/${RouteLinks.Password_Change}`,
  `/${RouteLinks.SignUp}`,
  `/${RouteLinks.Pricing}`,
  `/${RouteLinks.ReportCard}`,
  `/${RouteLinks.Create_Oneup_Assessment}`,
  "/create",
  "/sh",
  "/qna",
  "/feedback"
];
