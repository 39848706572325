import {DownloadBold} from "components/Icons";
import SizedBox from "components/SizedBox/SizedBox";
import {RouteLinks} from "globals/constants/routeLinks";
import {CandidateDetails} from "globals/types/APIResponses";
import React from "react";
import styles from "../CandidateDetails.module.scss";

interface Props {
  candidateAssessmentDetails: CandidateDetails | null;
}

const evenSpacing = "15px";

export const CandidateKPI: React.FC<Props> = ({candidateAssessmentDetails}) => {
  const candidateEmail =
    candidateAssessmentDetails?.data?.assessment?.candidate?.user?.email;
  const candidateSkills =
    candidateAssessmentDetails?.data?.assessment?.assessment_skills?.map(
      (t) => t?.skill?.id
    );

  const assessmentId =
    candidateAssessmentDetails?.data.assessment.ai_assessment_id;

  return (
    <div className={styles.kpi__container}>
      <p className={styles.kpi__title}>Anti-Cheating monitor</p>
      <SizedBox height="30px" />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Device Used</p>
        <p className={styles.kpi__value}>Desktop</p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Location</p>
        <p className={styles.kpi__value}>
          {candidateAssessmentDetails?.data?.assessment?.location}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Window Switch During Interview</p>
        <p className={styles.kpi__value}>
          {candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            ? candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            : 0}
        </p>
      </div>
      <SizedBox height={evenSpacing} />
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Webcam enabled</p>
        <p
          className={`${styles.kpi__value} ${styles.kpi__valueWithBackground}`}
        >
          YES
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={styles.row}>
        <p className={styles.kpi__name}>Mouse always in assessment window?</p>
        <p
          className={`${styles.kpi__value} ${styles.kpi__valueWithBackground} ${
            candidateAssessmentDetails?.data?.assessment?.total_tab_switches
              ? "!bg-red-500"
              : "bg-green-200"
          }`}
        >
          {candidateAssessmentDetails?.data?.assessment?.total_tab_switches
            ? "NO"
            : "YES"}
        </p>
      </div>
      <SizedBox height={evenSpacing} />

      <div className={`${styles.row} mt-3`}>
        <button
          className={styles.notify__button}
          onClick={() => {
            window.open(
              `/${
                RouteLinks.ReportCard
              }?assessmentId=${assessmentId}&email=${encodeURIComponent(
                candidateEmail!
              )}`
            );
          }}
        >
          Download reportcard
          <div className="ml-2 text-WHITE_700 hover:text-primary">
            <DownloadBold size={20} />
          </div>
        </button>
      </div>
    </div>
  );
};
