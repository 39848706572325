import {ChatProvider} from "components/ChatProvider";
import {FullScreenParameters} from "globals/types/globalTypes";
import {useCallback, useState} from "react";
import {useFullScreenHandle} from "react-full-screen";
import {SecondRoundInterviewFlow} from "routes/Interview/SecondRoundInterviewFlow";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer";
import GetHired from "./components/GetHired";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Hiring from "./components/Hiring";
import JoinJourney from "./components/JoinJourney";
import Technologies from "./components/TechnologiesLooper/Technologies";
import Testimonials from "./components/Testimonals";
import WhySourcebae from "./components/WhySourcebae";

const showDummy = false;

const LandingPageV2 = () => {
  const [fullScreenParameters, setFullScreenParameters] =
    useState<FullScreenParameters>({
      didUserPrompt: false,
      showModal: false,
      changeCount: 0
    });

  const handle = useFullScreenHandle();

  const handleFullScreenChange = useCallback(
    (state: boolean) => {
      if (!state && !fullScreenParameters.didUserPrompt)
        setFullScreenParameters({
          didUserPrompt: true,
          showModal: true,
          changeCount:
            fullScreenParameters.changeCount &&
            fullScreenParameters.changeCount + 1
        });
      else
        setFullScreenParameters({
          didUserPrompt: false,
          showModal: false,
          changeCount: fullScreenParameters.changeCount + 1
        });
    },
    [handle]
  );

  return showDummy ? (
    <>
      <button
        onClick={() => handle.enter()}
        className="absolute top-0 left-0 bg-red-300"
      >
        click me
      </button>
      <ChatProvider>
        <SecondRoundInterviewFlow
          fullScreenHandle={handle}
          handleFullScreenChange={handleFullScreenChange}
          isTestFinishing={false}
          showTimer={false}
          currentQuestionIndex={0}
          setCurrentQuestionIndex={() => {}}
          currentBehaviourQuestionIndex={1}
          setCurrentBehaviourQuestionIndex={() => {}}
          currentCodingQuestionIndex={0}
          setCurrentCodingQuestionIndex={() => {}}
          onPressEnd={() => {}}
          stopRecording={() => {}}
          videoConstraints={{width: 800, height: 400}}
          questions={[
            {
              ai_question_id: "",
              animation: "",
              assessmentId: 1,
              audio_url:
                "https://sourcebae.s3.ap-south-1.amazonaws.com/message_1729495720.mp3",
              question:
                "In a scenario where you need to collaborate with back-end developers to troubleshoot a CORS issue affecting API requests in your React application, how would you approach diagnosing the problem and working towards a resolution?",
              facialExpression: "",
              id: 1,
              lipsync_data: "",
              overall_type: "coding_analysis",
              question_answer: "",
              isFollowUpQuestion: false
            },
            {
              ai_question_id: "",
              animation: "",
              assessmentId: 1,
              audio_url:
                "https://sourcebae.s3.ap-south-1.amazonaws.com/message_1729495730.mp3",
              question:
                "Imagine you are working on a project that requires server-side rendering using Next.js. How would you structure your components and optimize the application to ensure fast initial load times and SEO performance while maintaining code maintainability and scalability?",
              facialExpression: "",
              id: 1,
              lipsync_data: "",
              overall_type: "coding_analysis",
              question_answer: "",
              isFollowUpQuestion: false,
              options: JSON.stringify(["option 1", "option 2", "option 3"]),
              sample_input: JSON.stringify("this is an input"),
              expected_output: JSON.stringify("this is the output which is 2")
            },
            {
              ai_question_id: "",
              animation: "",
              assessmentId: 1,
              audio_url:
                "https://sourcebae.s3.ap-south-1.amazonaws.com/message_1729495730.mp3",
              question: "add a function in js that adds 2 and 3",
              facialExpression: "",
              id: 1,
              lipsync_data: "",
              overall_type: "coding_analysis",
              question_answer: "",
              isFollowUpQuestion: false,
              options: JSON.stringify(["option 1", "option 2", "option 3"])
            },
            {
              ai_question_id: "",
              animation: "",
              assessmentId: 1,
              audio_url:
                "https://sourcebae.s3.ap-south-1.amazonaws.com/message_1729495730.mp3",
              question: "add a function in js that adds 2 and 3",
              facialExpression: "",
              id: 1,
              lipsync_data: "",
              overall_type: "coding_analysis",
              question_answer: "",
              isFollowUpQuestion: false,
              options: JSON.stringify(["option 1", "option 2", "option 3"])
            }
          ]}
        />
      </ChatProvider>
    </>
  ) : (
    <div
      className={
        "font-Glimer last-screen:mx-auto last-screen:flex last-screen:justify-center "
      }
    >
      <div className="max-w-[2000px]">
        <Header />
        <Hero />
        <Hiring />
        <GetHired />
        <Testimonials />
        <WhySourcebae />
        <JoinJourney />
        <Technologies />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPageV2;
