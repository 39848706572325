import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from "@tanstack/react-query";
import {APIEndPoints} from "globals/constants/endPoints";
import {postMixPanelEvent} from "globals/helpers";
import axiosPostModule from "network/Post/AxiosPostModule";

export const useSaveBehaviourQuestions = (
  assessmentId: string
): UseMutationResult<{data: any}, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["behaviourQuestionCreate"],
    mutationFn: async (data: any) => {
      return await axiosPostModule(
        APIEndPoints.ADD_BEHAVIOUR_QUESTION_TO_INTERVIEW(assessmentId),
        data
      ).then((res) => {
        queryClient.invalidateQueries(["behaviourQuestionCreate"]);
        return res;
      });
    },
    onError: (e) => {
      postMixPanelEvent("Error saving behaviour questions to node", e);
    }
  });
};
