import {Row} from "components/Row";
import {CandidateDetailsScreenshot} from "globals/types/globalTypes";
import {Images} from "Images";

const InterviewScreenshots = () => {
  const screenshots = JSON.parse(
    localStorage.getItem("interviewScreenshots")!
  ) as CandidateDetailsScreenshot[];

  return (
    <div className="p-12">
      <Row classNames="justify-center mb-10">
        <img
          src={Images.RecruitbaeLogoWithoutText}
          alt=""
          style={{width: 70, height: 70, textAlign: "center"}}
        />
        <p className="font-bold text-3xl ml-2">Recruitbae</p>
      </Row>
      <Row classNames="flex-wrap">
        {screenshots.length > 0 &&
          screenshots.map(({path}) => {
            return <img className="mb-8 rounded mr-20" src={path} />;
          })}
      </Row>
    </div>
  );
};

export default InterviewScreenshots;
