import {useCreateAssessmentProvider} from "components/CreateAssessmentProvider";
import {LeftArrow, RightArrow} from "components/Icons";
import Loader from "components/Loader";
import {Form, Formik} from "formik";
import strings from "globals/constants/strings.json";
import {CreateAsseessmentFields} from "globals/types/globalTypes";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import {useUploadScreenshot} from "hooks/interview/useUploadScreenshot";
import React, {useState} from "react";
import {toast} from "react-toastify";
import * as Yup from "yup";
import styles from "./style.module.scss";

interface Props {
  next: () => void;
  back: () => void;
}

const validateSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First name is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last name is required"),

  email: Yup.string().email().required("Email is required"),

  jd: Yup.string()
    .min(100, "Please enter a JD of more than 100 characters")
    .required("JD is requried")
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  jd: ""
};

export const IntervieweeDetails: React.FC<Props> = ({next, back}) => {
  const {createAssessmentValues} = useCreateAssessmentProvider();
  const {mutateAsync: uploadFile} = useUploadScreenshot({
    isResume: true
  });

  const [loading, setLoading] = useState<boolean>(false);

  const {mutateAsync: createUserAssessment} = useCreateAssessment(
    false,
    setLoading,
    true
  );

  const submitForm = async (values: typeof initialValues) => {
    try {
      setLoading(true);

      const {skills, date, resume, years_of_experience} =
        createAssessmentValues;
      const {firstName, lastName, email, jd} = values;

      const formData = new FormData();
      formData.append("file", resume!);
      const {
        data: {
          data: {path: resume_url}
        }
      } = await uploadFile(formData);

      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);
      const payload: CreateAsseessmentFields = {
        resume_url,
        scheduled_date: date!.format("YYYY-MM-DD"),
        candidate_name: firstName,
        candidate_last_name: lastName,
        email,
        experience_duration: years_of_experience,
        skills_ids: skills.map((skill) => skill.value),
        responseURL: false,
        organization_id: userOrgId.user.teams[0].organization.id,
        assessment_name: `${skills[0].label} assessment`,
        job_description: jd
      };

      createUserAssessment(payload).then((res) => {
        if (res) {
          if (res.data.success) {
            toast.success("Assessment Created", {
              position: "bottom-left"
            });
            setLoading(false);
            next();
          }
        }
      });
    } catch (e) {
      setLoading(false);
      next();
      toast.error(
        "We are sorry this assessment could not be created because of a technical error on our end."
      );
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div>
        <p className="font-bold text-2xl mb-4">Candidate Details</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={submitForm}
      >
        {(formik) => {
          const {
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur
          } = formik;
          return (
            <Form onSubmit={handleSubmit}>
              <div className="pl-2 max-w-full">
                <div>
                  <div className="flex items-center">
                    <div className="flex-1 relative flex flex-col">
                      <p className="text-sm">{strings.first_name}</p>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={handleChange("firstName")}
                        onBlur={handleBlur}
                        value={values?.firstName}
                        className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                        placeholder={strings.first_name}
                      />
                      {errors.firstName && touched.firstName && (
                        <span className="text-red-500 text-xs mt-2">
                          {errors.firstName}
                        </span>
                      )}
                    </div>

                    <div className="flex-1 relative flex flex-col">
                      <p className="text-sm">{strings.last_name}</p>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={handleChange("lastName")}
                        onBlur={handleBlur}
                        value={values?.lastName}
                        className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                        placeholder={strings.last_name}
                      />
                      {errors.lastName && touched.lastName && (
                        <span className="text-red-500 text-xs mt-2">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <p className="text-sm">{strings.email}</p>
                  <div className="relative flex flex-col">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur}
                      value={values?.email}
                      className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                      placeholder={strings.email}
                    />
                    {errors.email && touched.email && (
                      <span className="text-red-500 text-xs mt-2">
                        {errors.email}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <p className="text-sm">Job description</p>
                  <div className="relative flex flex-col">
                    <textarea
                      name="jd"
                      id="jd"
                      onChange={handleChange("jd")}
                      onBlur={handleBlur}
                      value={values.jd}
                      className="mt-2 bg-white-800 w-full border border-white-300 border-solid rounded p-4 text-sm min-h-36"
                      placeholder={"Job description..."}
                    />
                    {errors.jd && touched.jd && (
                      <span className="text-red-500 text-xs mt-2">
                        {errors.jd}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between mt-20">
                <div className={styles.back_btn_wrap} onClick={() => back()}>
                  <LeftArrow className={styles.back_icon} size={20} />
                  <button className={styles.back_button}>Back</button>
                  <div className={styles.back_btn_after}></div>
                </div>

                <button className={styles.next_btn_wrap} type="submit">
                  <div className={styles.next_btn_before}></div>
                  <button className={styles.next_button}>Create</button>
                  <RightArrow className={styles.icon} size={20} />
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
