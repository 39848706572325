import axios from "axios";
import CryptoJS from "crypto-js";
import gqlmin from "gqlmin";
import mixpanel from "mixpanel-browser";
import moment, {Moment} from "moment";
import axiosGetModule from "network/Get/AxiosGetModule";
import GoogleAnalytics from "react-ga";
import {toast} from "react-toastify";
import {APIEndPoints} from "./constants/endPoints";
import {MicroCopyValues} from "./constants/microcopies";
import {
  CandidateDetails,
  DeleteAssessmentTestResponse,
  LoginResponse,
  ParseResume,
  SkillsResponse
} from "./types/APIResponses";
import {
  CreateAssessmentValues,
  DateType,
  GetMicroCopy,
  Microcopy,
  SecondRoundFollowUpOrRealTimeQuestion,
  SecondRoundQuestion,
  SecondRoundQuestionType
} from "./types/globalTypes";

export const getDeviceName = () => {
  let deviceName = "Unknown";
  const deviceNameMapping: any = {
    "Windows Phone": /Windows Phone/i,
    Windows: /Win/i,
    iPhone: /iPhone/i,
    iPad: /iPad/i,
    Android: /Android/i,
    Blackberry: /BlackBerry/i,
    Macintosh: /Mac/i,
    Linux: /Linux/i,
    Palm: /Palm/i
  };

  Object.keys(deviceNameMapping).map(
    (platform) =>
      navigator.userAgent.match(deviceNameMapping[platform]) &&
      (deviceName = platform)
  );
  return deviceName;
};

export const getDateDifferenceInSeconds = (
  startDate: DateType,
  endDate: DateType
) => {
  const seconds = (endDate?.getTime()! - startDate?.getTime()!) / 1000;
  return seconds;
};

export const staticHeaders = () => ({
  Authorization: `Bearer ${
    localStorage.getItem("AUTH_TOKEN") ?? localStorage.getItem("candidateToken")
  }`
});

export const formatYYYYMMDD = (date?: Moment | null) => {
  return moment(date || moment())
    .format("YYYY-MM-DD")
    .toString();
};

export const formatDDMMMYYYY = (date?: Moment | string | null) => {
  return moment(date || moment())
    .format("DD MMM YYYY")
    .toString();
};

export const deleteAssessmentTest = async (id: number) => {
  try {
    return await axios.request<DeleteAssessmentTestResponse>({
      url: `${process.env.REACT_APP_BASE_NODE_URL}/assesment-templates/delete/${id}`,
      method: "DELETE",
      headers: staticHeaders()
    });
  } catch (e) {
    return null;
  }
};

export const fetchSkills = async () => {
  try {
    return axiosGetModule(APIEndPoints.GET_SKILLS_NODE).then(
      (res: SkillsResponse) => res.data
    );
  } catch (e) {
    return null;
  }
};

export const base64Toblob = async (data: string) => {
  return await fetch(data).then((res) => res.blob());
};

export const initMixpanel = () => {
  mixpanel.init("5384ef4048831dcf16ecbf2912fde4cf");
};

export const createMixPanelUser = (userDetails: LoginResponse) => {
  const {user} = userDetails.data;
  mixpanel.alias(user.id.toString());
  mixpanel.people.set({
    Type:
      user.role_id === 1
        ? "Admin"
        : user.role_id === 2
        ? "Manager"
        : user.role_id === 3
        ? "Developer"
        : user.role_id === 4 && "BDE",

    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email
  });
  mixpanel.identify(user.id.toString());
};

export const postMixPanelEvent = (event: string, data?: any) => {
  initMixpanel();
  mixpanel.track(event, data);
};

export const postGoogleAnalyticsEvent = (category: string) => {
  const eventTracker = (action: string, label: string) => {
    GoogleAnalytics.event({category, action, label});
  };
  return eventTracker;
};

export const decodeEncodedAPIResponse = (
  responseString: string,
  res: any
): any => {
  let bytes = CryptoJS.AES.decrypt(
    `${responseString}`,
    process.env.REACT_APP_DECODE_KEY!
  ).toString(CryptoJS.enc.Utf8);

  return {...res, data: JSON.parse(bytes)};
};

export const getIPOfUser = async () => {
  return await axios
    .get("https://api.ipify.org/?format=json")
    .then((res) => res.data.ip)
    .catch((err) => "");
};

export const encrypt = async (data: any) => {
  try {
    const iv = CryptoJS.lib.WordArray.random(16);
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_DECODE_KEY!
    ).toString();
  } catch (error) {
    throw error;
  }
};

// replace
export const parseResume = async (data: any) => {
  return await axios.post<ParseResume>(APIEndPoints.PARSE_RESUME, data, {
    headers: staticHeaders()
  });
};

export function getParentContainerDimensions(
  element: Element
): {width: number; height: number} | null {
  if (element.parentElement !== null)
    return {
      width: element.parentElement.clientWidth,
      height: element.parentElement.clientHeight
    };
  else return null;
}

export function getElementDimensions(
  element: Element
): {width: number; height: number} | null {
  if (element) {
    return {width: element.clientWidth, height: element.clientHeight};
  }
  return null;
}

export function convertToBase64(
  file: any,
  callBack: (base64String: string | ArrayBuffer | null) => void
) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callBack(reader.result);
  };
}

export function capitalizeWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export async function getAssessmentDetails(
  assessmentId: string
): Promise<CandidateDetails | null | undefined> {
  return axios
    .get(`${APIEndPoints.CANDIDATE_DETAILS_NODE(assessmentId)}`, {
      headers: staticHeaders()
    })
    .then((res) => {
      if (res.data.success === true && res.data.code === 200) {
        const {data} = res.data;
        const decodedResponse: CandidateDetails = decodeEncodedAPIResponse(
          data,
          res.data
        );
        console.log("decoded", decodedResponse);
        return decodedResponse;
      }
    })
    .catch((e) => {
      toast.error("Unable to get data");
      return null;
    });
}

export async function getSpeechSounds(): Promise<SpeechSynthesisVoice[]> {
  return new Promise((resolve, reject) => {
    let synth = window.speechSynthesis;
    let id = setInterval(() => {
      if (synth.getVoices().length !== 0) {
        resolve(synth.getVoices());
        clearInterval(id);
      }
    }, 10);
  });
}

export function base64ToBlob(base64: string) {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

export const getContentfulData = async <T>(
  query: string,
  variables: Record<string, unknown> = {}
): Promise<{data: T; errors?: Record<string, unknown>}> => {
  const res = await fetch(
    `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`
      },
      body: JSON.stringify({
        query: gqlmin(query),
        variables
      })
    }
  );

  return res.json();
};

export const generateGetMicroCopy = (microCopy: Microcopy[]) => {
  const replaceVariables: GetMicroCopy = (copy = "", variables = {}) =>
    // @ts-ignore
    Object.entries(variables).reduce(
      // @ts-ignore
      (acc, [key, value]) => acc.replace(`{{${key}}}`, value),
      copy
    );

  const getMicroCopy: GetMicroCopy = (path, variables) => {
    const copy = microCopy.find(({key}) => key === path)?.value;
    return (
      replaceVariables(copy as MicroCopyValues, variables) || `MC: ${path}`
    );
  };

  return getMicroCopy;
};

export const strToBool = (str: string) => str === "true";

export const createSecondRoundQuestion = ({
  ai_question_id,
  assessment_id,
  audio_file_path_mp3,
  id,
  overall_type,
  questionText,
  isFollowUpQuestion,
  options,
  isTechnicalQuestion,
  isBehaviourQuestion,
  assessment_behaviour_question_options,
  sample_input,
  expected_output
}: SecondRoundFollowUpOrRealTimeQuestion): SecondRoundQuestion => {
  return {
    ai_question_id,
    animation: null,
    assessmentId: assessment_id,
    audio_url: audio_file_path_mp3 ?? "",
    facialExpression: null,
    id: id ?? Math.random(),
    lipsync_data: null,
    overall_type,
    question: questionText,
    question_answer: "",
    isFollowUpQuestion,
    options: isTechnicalQuestion
      ? JSON.stringify([])
      : isBehaviourQuestion
      ? JSON.stringify(options)
      : "",
    assessment_behaviour_question_options: assessment_behaviour_question_options
      ? assessment_behaviour_question_options
      : [],
    sample_input: sample_input ?? "",
    expected_output: expected_output ?? ""
  };
};

export const createEmptyCreateAssessmentValues =
  (): CreateAssessmentValues => ({
    candidate_source: "",
    codingQuestions: [],
    date: moment(),
    email: "",
    first_name: "",
    is_contractual: 0,
    jd: "",
    last_name: "",
    project_info: [],
    questions: "",
    resume: null,
    skills: [],
    years_of_experience: "",
    include_intro: 0,
    include_round_one: 0,
    include_round_two: 0
  });

export const sortInterviewQuestions = (
  questions: SecondRoundQuestion[]
): SecondRoundQuestion[] => {
  const greetingQuestions = [...questions].filter(
    (e) => e.overall_type === "greetings"
  );
  let technicalQuestions = [...questions].filter(
    (e) => e.overall_type === "Technical_analysis"
  );
  let behaviourQuestions = [...questions].filter(
    (e) => e.overall_type === "behavioral_analysis"
  );
  let codingQuestions = [...questions].filter(
    (e) => e.overall_type === "coding_analysis"
  );

  if (technicalQuestions.length > 14) {
    technicalQuestions = technicalQuestions.slice(0, 14);
  }
  if (behaviourQuestions.length > 14) {
    behaviourQuestions = behaviourQuestions.slice(0, 5);
  }
  if (codingQuestions.length > 3) {
    codingQuestions = codingQuestions.slice(0, 3);
  }

  const priority: Record<SecondRoundQuestionType, number> = {
    greetings: 1,
    Technical_analysis: 2,
    behavioral_analysis: 3,
    coding_analysis: 4
  };

  const updatedQuestionList = [
    ...greetingQuestions,
    ...technicalQuestions,
    ...behaviourQuestions.sort(
      (a, b) => Number(b.is_answered) - Number(a.is_answered)
    ),
    ...codingQuestions
  ];

  updatedQuestionList.sort(
    (a, b) => priority[a.overall_type] - priority[b.overall_type]
  );

  return updatedQuestionList;
};

export const checkIfMobileDevice = () => {
  return "ontouchstart" in window;
};
