import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import CodeEditor from "components/CodeEditor/CodeEditor";
import InterviewHeader from "components/InterviewHeader";
import {Row} from "components/Row";
import {Webcam} from "components/Webcam";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import styles from "./CodingRound.module.css";

interface Props {
  handleQuestionChange: () => void;
  question: string;
  currentQuestionNumber: number;
  sampleInput?: string;
  sampleOutput?: string;
  stopRecording?: () => void;
}

const CodingRound = ({
  handleQuestionChange,
  question,
  currentQuestionNumber,
  sampleInput,
  sampleOutput,
  stopRecording
}: Props) => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");

  const {interview_details} = useAppCommonDataProvider();
  const {data} = interview_details || {};
  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);

  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    if (currentQuestionNumber === 1) {
      setShowBanner(true);
      setTimeout(() => {
        setShowBanner(false);
      }, 30000);
    }
  }, [currentQuestionNumber]);

  return (
    <div className={styles.main_wrapper}>
      <InterviewHeader
        isDarkMode
        title="Coding Round"
        showTimer
        initialTime={data?.assessment.coding_duration ?? 2700}
        handleTimerChange={(remaining) => {
          if (remaining % 10 === 0) {
            updateAssessment({coding_duration: remaining});
          }
        }}
        showTabSwitches
      />

      {showBanner && (
        <Row classNames="bg-yellow-500">
          <p
            className="text-red-900 font-extrabold py-2 min-w-9/10 px-6"
            style={{maxWidth: "90%"}}
          >
            If your interview has been created for the mentioned
            technologies—Cloud, DevOps, Infrastructure, CRM, Tools, Frameworks,
            Testing, and UI/UX—then you can skip the coding part for now
          </p>
          <button
            onClick={stopRecording}
            style={{
              width: "80px",
              height: "30px",
              fontWeight: "500px",
              background: "#785dd2",
              color: "#fff",
              boxShadow: "0px 29px 64px 0px #BABABA1A",
              marginLeft: 10
            }}
          >
            Skip
          </button>
        </Row>
      )}

      <div className="flex px-[60px] bg-navyBlue-700 h-[87vh]">
        {/* Left Side */}
        <div className="w-2/5 h-full p-[20px]">
          <div className="p-6 h-[57vh] rounded-xl bg-[#130E1F] overflow-y-scroll">
            <h2 className="text-white-900 text-14">{`Question (${currentQuestionNumber}/3)`}</h2>
            <p className="text-white-900 text-18 font-medium mt-[6px]">
              {question}
            </p>

            <div className="bg-white-900 p-2 my-6 rounded">
              <p className="font-medium">{`Sample input: ${sampleInput}`}</p>
            </div>
            <div className="bg-white-900 p-2 rounded">
              <p className="font-medium">{`Sample output: ${sampleOutput}`}</p>
            </div>
          </div>
          <div className="flex w-full justify-between gap-x-5 mt-5">
            <div className="rounded-xl w-full overflow-hidden">
              <Webcam />
            </div>
            <div className="rounded-xl w-full bg-[#130E1F] flex justify-center items-center">
              <img
                className="w-[104px] h-[104px]"
                src="https://images.ctfassets.net/7oc31naqqojs/4xddgiW20opOeM9J9KVdJF/69f159e1a13bbbdf48dd35534382bc2b/receffect.png"
              />
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="w-3/5 pt-[20px] flex h-full ">
          <CodeEditor
            handleQuestionChange={handleQuestionChange}
            currentQuestionNumber={currentQuestionNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default CodingRound;
