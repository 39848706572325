import {capitalizeWord} from "globals/helpers";

type Props = {
  label: string;
  value: string;
  description: string;
  titleHasPrimaryColor: boolean;
  hasBottomBorder: boolean;
};

const ReportCard: React.FC<Props> = ({
  label,
  value,
  description,
  titleHasPrimaryColor,
  hasBottomBorder
}) => {
  const [time, amount] = value.split("&");
  const [number, hours] = time.split(" ");
  const [cost, currency] = amount.split(" ");

  return (
    <div
      className={`flex flex-col py-4 ${
        hasBottomBorder ? "border-b-1px border-b-white-300" : ""
      }`}
    >
      <p
        className={`${
          titleHasPrimaryColor ? "text-primary" : "text-lightBlack-500"
        } font-bold mb-2`}
      >
        {label}
      </p>
      <div className="flex bg-[#345b631f] rounded p-4">
        <div className="w-1/2 text-center border-r-1px border-r-gray-400">
          <p className="font-bold text-2xl">{number}</p>
          <p className="text-gray-400 font-bold">{capitalizeWord(hours)}</p>
        </div>

        <div className="w-1/2 text-center">
          <p className="font-bold text-2xl">
            {currency}
            {cost}
          </p>
          <p className="text-gray-400 font-bold">{"Cost"}</p>
        </div>
      </div>
      {!hasBottomBorder && (
        <p className="text-xs font-bold italic text-primary bg-primary bg-opacity-20 mt-4 rounded p-2">
          {description}
        </p>
      )}
      {/* <p
        className={`font-bold ${
          titleHasPrimaryColor ? "text-primary" : "text-lightBlack-300"
        } capitalize`}
      >
        {label}
      </p>
      <SizedBox height="6px" />
      <p
        className="text-42 font-bold text-primary text-center"
        style={{color: "#505050"}}
      >
        {value}
      </p>
      <p className="text-xs font-medium italic text-primary text-center">
        {description}
      </p> */}
    </div>
  );
};

export default ReportCard;
