import Clarity from "@microsoft/clarity";
import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import "./index.css";

Clarity.init(process.env.REACT_APP_CLARITY_ID!);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
