import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ViewButton from "components/Containers/Button/Button/ViewButton";
import copy from "copy-to-clipboard";
import {AppColors} from "globals/appcolors";
import {CandidateLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {formatDDMMMYYYY} from "globals/helpers";
import React, {CSSProperties} from "react";
import {Spinner} from "react-activity";
import {useNavigate} from "react-router-dom";
import {Column, useTable} from "react-table";
import {toast} from "react-toastify";
import {AssessTypes} from "../Types";
import styles from "./ReactTable.module.scss";

interface TableProps {
  columns: Array<Column>;
  data: Array<any>;
  type: AssessTypes;
  className?: CSSProperties | undefined;
  loading?: boolean;
  metaData?: any;
}

interface Props {
  column: Array<Column>;
  row: Array<any>;
  assessType: AssessTypes;
  loading?: boolean;
  metaData?: any;
}

const PendingAssessmentsTable: React.FC<TableProps> = ({columns, data}) => {
  const navigate = useNavigate();
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data
    });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className="my-4" {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index.toString()} style={{width: "60px"}}>
                    <p
                      className={`text-base font-semibold  text-ellipsis whitespace-nowrap overflow-hidden capitalize ${
                        [0].includes(index) ? "cursor-pointer" : "cursor-auto"
                      }`}
                      onClick={() => {
                        if (index === 0) {
                          navigate(
                            //@ts-ignore
                            `/${CandidateLinks.Candidate_Detail}?candidate=${cell.row.original.candidate_id}`
                          );
                        }
                      }}
                      style={{
                        background:
                          index === 4
                            ? cell.value === "Completed"
                              ? "#edfbf1"
                              : "#fff7d4"
                            : "transparent",
                        color:
                          index === 0
                            ? AppColors.PRIMARYCOLOR
                            : index === 4
                            ? cell.value === "Completed"
                              ? "#2FAF5B"
                              : "#B59506"
                            : "#505050",
                        padding: index === 4 ? ".2rem 1rem" : 0,
                        borderRadius: index === 4 ? 5 : 0
                      }}
                    >
                      {cell.value
                        ? index === 1
                          ? formatDDMMMYYYY(cell.value)
                          : cell.value
                        : "N.A."}
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const AllInterviews: React.FC<TableProps> = ({columns, data}) => {
  const navigate = useNavigate();
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data
    });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className="my-4" {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index.toString()} style={{width: "60px"}}>
                    <p
                      className={`text-base font-semibold  text-ellipsis whitespace-nowrap overflow-hidden capitalize ${
                        [0].includes(index)
                          ? "text-blue-900 cursor-pointer"
                          : "text-lightBlack-300 cursor-auto"
                      }`}
                      onClick={() => {
                        if (index === 0) {
                          navigate(
                            // @ts-ignore
                            `/${CandidateLinks.Candidate_Detail}?candidate=${cell.row.original.candidate_id}`
                          );
                        }
                      }}
                    >
                      {cell.value
                        ? index === 1
                          ? formatDDMMMYYYY(cell.value)
                          : cell.value
                        : index === 4
                        ? 0
                        : index === 3 //@ts-ignore
                        ? cell.row.original.assessment_total_points
                        : "N.A."}
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Table: React.FC<TableProps> = ({
  columns,
  data,
  type,
  loading,
  metaData
}) => {
  const navigate = useNavigate();
  const {
    setnewInterviewsPageCount,
    newInterviewsPageCount,
    oldInterviewsPageCount,
    setoldInterviewsPageCount
  } = useAppCommonDataProvider();

  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data
    });

  const onCopy = async (text: string) => {
    copy(text);
    toast.success(strings.candidateDetails.copyToast);
  };
  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className="my-4" {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <td key={index.toString()} style={{width: "60px"}}>
                    <p
                      className={`text-base font-semibold  text-ellipsis whitespace-nowrap overflow-hidden capitalize ${
                        [row.cells.length - 1, 0].includes(index)
                          ? "text-blue-900 cursor-pointer"
                          : "text-lightBlack-300 cursor-auto "
                      }`}
                      onClick={() => {
                        row.cells.length === index + 1
                          ? onCopy(
                              `${process.env.REACT_APP_DOMAIN}/candidate/login`
                            )
                          : index === 0 &&
                            navigate(
                              //@ts-ignore
                              `/${CandidateLinks.Candidate_Detail}?candidate=${cell.row.original.candidate_id}`
                            );
                      }}
                    >
                      {row.cells.length === index + 1
                        ? "Tap to Copy"
                        : cell.value}
                    </p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {type === AssessTypes.NEW &&
        !metaData?.last_page === metaData?.current_page && (
          <div className="flex flex-col items-center">
            <ViewButton
              invert
              name={loading ? <Spinner size={15} /> : strings.more}
              isDisabled={loading ? true : false}
              onClick={() => {
                !type
                  ? setoldInterviewsPageCount?.({
                      ...oldInterviewsPageCount,
                      count: oldInterviewsPageCount.count + 1
                    })
                  : setnewInterviewsPageCount?.({
                      ...newInterviewsPageCount,
                      count: newInterviewsPageCount.count + 1
                    });
              }}
              style={{paddingInline: "50px"}}
            />
          </div>
        )}
      {/* {type === AssessTypes.OLD && !oldInterviewsPageCount.hasReachedLast && (
        <div className="flex flex-col items-center">
          <ViewButton
            invert
            name={loading ? <Spinner size={15} /> : strings.more}
            isDisabled={loading ? true : false}
            onClick={() => {
              type
                ? setoldInterviewsPageCount?.({
                    ...oldInterviewsPageCount,
                    count: oldInterviewsPageCount.count + 1,
                  })
                : setnewInterviewsPageCount?.({
                    ...newInterviewsPageCount,
                    count: newInterviewsPageCount.count + 1,
                  });
            }}
            style={{ paddingInline: "50px" }}
          />
        </div>
      )} */}
    </table>
  );
};

const DashboardTable: React.FC<Props> = ({
  column,
  row,
  assessType,
  loading
}) => {
  const columns: Array<Column> = [...column];
  const data: Array<any> = [...row];

  return (
    <div className={styles.react_table}>
      {assessType === 1 ? (
        <Table
          type={assessType}
          columns={columns}
          data={data}
          className={styles.react_table}
          loading={loading}
        />
      ) : assessType === 2 ? (
        <AllInterviews
          columns={columns}
          type={assessType}
          data={data}
          className={styles.react_table}
          loading={loading}
        />
      ) : (
        <PendingAssessmentsTable
          columns={columns}
          type={assessType}
          data={data}
          className={styles.react_table}
          loading={loading}
        />
      )}
    </div>
  );
};

export default DashboardTable;
