import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {Refresh} from "components/Icons";
import Loader from "components/Loader";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {allInterviewStatusesOptions} from "globals/constants/dropdownConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {CandidateLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import {
  AssessmentData,
  AssessmentSkill,
  DashboardData
} from "globals/types/globalTypes";
import axiosGetModule from "network/Get/AxiosGetModule";
import React, {useEffect, useState} from "react";
import {Spinner} from "react-activity";
import {useNavigate} from "react-router-dom";
import {Cell, Legend, Pie, PieChart, Tooltip} from "recharts";
import DashboardTable from "routes/dashboard/DashboardHome/DashboardTable/ReactTable";
import ReportInfoCard from "routes/dashboard/DashboardHome/DashboardTable/ReportInfoCard/ReportInfoCard";
import TableTopTabs from "routes/dashboard/DashboardHome/TableTopTabs/TableTopTabs";
import {
  AssessTypes,
  EventDataProps,
  PendingTableRowProps,
  ReportInfoProps,
  TableRowProps
} from "routes/dashboard/DashboardHome/Types";
import styles from "./Dashboard.module.scss";

export interface AssessmentDataNode {
  data: {
    data: {
      assessment_duration: number;
      assessment_level: number;
      assessment_points_scored: string | null;
      assessment_total_points: string | null;
      assessment_template_id: number;
      assessment_url: URL | string;
      candidate: {
        experience_level: number;
        id: number;
        resume_file_id: number | null;
        source: string;
        user: {
          id: number;
          first_name: string;
          last_name: string;
        };
        user_id: number;
      };
      candidate_id: number;
      created_at: string;
      created_by: number;
      deleted_at: any;
      experience_duration: string;
      hold_reason: any;
      id: number;
      include_organization_questions: number;
      ip_address: string | null;
      ip_change_count: number | null;
      location: string | null;
      name: string;
      number_of_questions: number | null;
      organization: {
        id: number;
        logo: URL | string;
        name: string;
      };
      organization_id: number;
      scheduled_date: string;
      skills: AssessmentSkill[];
      status: number;
      time_remaining: null | number;
      time_taken: null | number;
      total_tab_switches: number | null;
      updated_at: string;
      updated_by: number;
    };
    meta: {
      current_page: number;
      from: number;
      last_page: number;
      per_page: number;
      to: number;
      total: number;
    };
  };
}

const Dashboard: React.FC = () => {
  const column = [
    {Header: "Name", accessor: "name"},
    {Header: "Test", accessor: "test"},
    {Header: "Experience", accessor: "experience"},
    {Header: "Agency Name", accessor: "agencyName"}
  ];

  const pendingAssessmentsColumn = [
    {Header: "Name", accessor: "name"},
    {Header: "Assessment Date", accessor: "updated_at"},
    {Header: "Location", accessor: "location"},
    {Header: "Tab Switches", accessor: "total_tab_switches"},
    {Header: "Status", accessor: "status"}
  ];

  const colors = [
    `${AppColors.PRIMARYCOLOR}`,
    `${AppColors.PRIMARYCOLOR}b0`,
    `${AppColors.PRIMARYCOLOR}70`,
    `${AppColors.PRIMARYCOLOR}50`
  ];
  const {loading, setLoading} = useAppCommonDataProvider();
  const eventTracker = postGoogleAnalyticsEvent("Dashboard");
  const navigate = useNavigate();

  const [selectedTab, setselectedTab] = useState<number>(0);
  const [isFetching, setisFetching] = useState<boolean>(true);
  const [dashboardData, setdashboardData] = useState<DashboardData>();
  const [eventData, seteventData] = useState<EventDataProps>();
  const [reportInfo, setReportInfo] = useState<ReportInfoProps[]>();
  const [todaysAssess, settodaysAssess] = useState<TableRowProps[]>([]);
  const [metaData, setMetaData] = useState<[]>([]);
  const [chartData, setChartData] = useState<
    Array<{name: string; value: number}>
  >([]);
  const [fetchingMoreRecords, setFetchingMoreRecords] =
    useState<boolean>(false);
  const [pendingAssessments, setPendingAssessments] = useState<
    PendingTableRowProps[]
  >([]);
  const [pieChartCheck, setPieChartCheck] = useState<boolean>(false);
  const [assessmentCount, setassessmentCount] = useState<Array<{
    label: string;
    value: number;
    icon: string | OverridableComponent<any>;
  }> | null>(null);

  const onTabsClick = (tab: number) => {
    setselectedTab(tab);
  };

  const fetchDashboardDetails = async (day?: string) => {
    setLoading?.(true);
    await axiosGetModule(APIEndPoints.GET_DASHBOARD_NODE(day))
      .then((res: DashboardData) => {
        seteventData(res.data.events_data);
        setdashboardData(res);
        setReportInfo([
          {
            label: "Total time and cost Recruitbae saved till date",
            value: `${res.data.card_data.cost_saved_by_recruitbae} hours & ${res.data.card_data.time_saved_by_recruitbae}$`,
            description: `${(
              res.data.card_data.cost_saved_by_recruitbae /
              res.data.card_data.total_candidate
            ).toFixed(2)} ${
              res.data.card_data.cost_saved_by_recruitbae /
                res.data.card_data.total_candidate >
              1
                ? "hours"
                : "hour"
            } average time per interview & $${(
              res.data.card_data.cost_saved_by_recruitbae /
              res.data.card_data.total_candidate
            ).toFixed(2)} average cost if we conduct your interview`
          },
          {
            label: "Total Time and Cost You Saved Till Date",
            value: `${res.data.card_data.cost_saved_by_you} hours & ${res.data.card_data.time_saved_by_you}$`,
            description: `${(
              res.data.card_data.cost_saved_by_you /
              res.data.card_data.total_candidate
            ).toFixed(2)} ${
              res.data.card_data.cost_saved_by_you /
                res.data.card_data.total_candidate >
              1
                ? "hours"
                : "hour"
            } average time per interview & $${(
              res.data.card_data.cost_saved_by_you /
              res.data.card_data.total_candidate
            ).toFixed(2)} average cost if we conduct your interview`
          }
        ]);
        if (res.data.card_data.total_candidate_completed == 0)
          setPieChartCheck(true);
        const totalCandidatesForChart =
          res.data.card_data.total_candidate_completed +
          res.data.card_data.total_candidate_hired +
          res.data.card_data.total_candidate_shortlisted +
          res.data.card_data.total_candidate_rejected;

        setChartData([
          {
            name: "Total candidates completed",
            value: parseFloat(
              (
                (res.data.card_data.total_candidate_completed * 100) /
                totalCandidatesForChart
              ).toFixed(2)
            )
          },
          {
            name: "Total hired",
            value: parseFloat(
              (
                (res.data.card_data.total_candidate_hired * 100) /
                totalCandidatesForChart
              ).toFixed(2)
            )
          },
          {
            name: "Total candidates shortlisted",
            value: parseFloat(
              (
                (res.data.card_data.total_candidate_shortlisted * 100) /
                totalCandidatesForChart
              ).toFixed(2)
            )
          },
          {
            name: "Total candidates rejected",
            value: parseFloat(
              (
                (res.data.card_data.total_candidate_rejected * 100) /
                totalCandidatesForChart
              ).toFixed(2)
            )
          }
        ]);
      })
      .finally(() => setTimeout(() => setLoading?.(false), 500));
  };

  const fetchTodaysAssess = () => {
    axiosGetModule(APIEndPoints.GET_ASSESSMENTS)
      .then((res: {data: AssessmentDataNode[]}) => {
        //@ts-ignore
        const todays: TableRowProps[] = res?.data?.data?.map(
          (assessment: any) => {
            const {candidate, name, scheduled_date, assessment_url} =
              assessment;
            const {experience_level, source, user, user_id, id} = candidate;
            const {first_name, last_name} = user;
            return {
              user_id: user_id.toString(),
              name: `${first_name} ${last_name}`,
              test: name,
              experience: experience_level.toString(),
              agencyName: source,
              date: scheduled_date,
              interviewLink: assessment_url,
              candidate_id: id
            };
          }
        ); //@ts-ignore
        setMetaData(res?.data?.meta);
        settodaysAssess(todays);
        setisFetching(false);
      })
      .catch((e) => {
        console.log(e);
        setisFetching(false);
      });
  };

  const fetchPendingAssessments = () => {
    axiosGetModule(APIEndPoints.GET_PENDING_STATUS_ASSESSMENTS)
      .then((res: {data: AssessmentData[]}) => {
        const pending: PendingTableRowProps[] = [...res.data]?.map(
          (assessment) => {
            const {
              assessment_total_points,
              name,
              location,
              status,
              total_tab_switches,
              updated_at,
              candidate_id
            } = assessment;

            return {
              assessment_total_points,
              candidate_id,
              name,
              location,
              status: allInterviewStatusesOptions?.[status - 1]?.label,
              total_tab_switches,
              updated_at
            };
          }
        );
        setPendingAssessments(pending);
      })
      .catch((e) => console.log(e));
  };

  const fetchCount = () => {
    axiosGetModule(APIEndPoints.GET_ASSESSMENT_COUNT).then((res) =>
      setassessmentCount([
        {
          label: "Interviews created",
          value: res.data.all_assessment,
          icon: "AI"
        },
        {
          label: "Interviews done",
          value: res.data.completed_assessment,
          icon: PersonOutlineOutlinedIcon
        }
      ])
    );
  };

  useEffect(() => {
    fetchTodaysAssess();
    fetchDashboardDetails();
    fetchPendingAssessments();
    fetchCount();
    eventTracker("Dashboard viewed", "User landed on dashboard");
  }, []);

  if (isFetching) return <Loader />;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.candidate__container}>
            <p className={styles.title__text}>
              {strings.dashboard.recentReport.title}
            </p>
            <button onClick={() => fetchDashboardDetails()}>
              <Refresh size={40} color={AppColors.LIGHTBLACK_500} />
            </button>
          </div>
          {loading ? (
            <div className={styles.candidate__loaderContainer}>
              <Spinner size={30} />
            </div>
          ) : (
            <>
              <div className={styles.candidate__tableHeading}>
                <p className="font-bold">Name</p>
              </div>
              {dashboardData &&
              dashboardData.data.card_data.recent_candidates.length ? (
                dashboardData?.data.card_data.recent_candidates?.map(
                  (candidate, index) => {
                    return (
                      <div
                        className={styles.individual__candidateContainer}
                        key={`${candidate.id} ${index}`}
                      >
                        <p
                          className={styles.candidate__name}
                          onClick={() =>
                            navigate(
                              `/${CandidateLinks.Candidate_Detail}?candidate=${candidate.id}`
                            )
                          }
                        >
                          {`${candidate.first_name} ${candidate.last_name}`}
                        </p>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="mx-5">
                  <p className="text-center font-bold text-lg italic">
                    No Candidates
                  </p>
                </div>
              )}
            </>
          )}
        </div>

        <div className="w-65 flex">
          <div className="w-full">
            <div className="mb-6 flex">
              {assessmentCount &&
                assessmentCount.map(({value, label, icon: Icon}) => {
                  return (
                    <div className="shadow-md p-4 bg-white-900 border border-white-300 rounded-lg flex mr-8 min-w-64 items-center">
                      <div className="flex flex-col">
                        <p className="font-bold text-2xl">{value}</p>
                        <p className="text-sm font-medium mt-1 text-lightBlack-300">
                          {label}
                        </p>
                      </div>

                      <div className="flex justify-end flex-1">
                        <div className="shadow-md bg-white-900 border border-white-300 py-2 px-4 rounded">
                          {typeof Icon === "string" ? (
                            <p className="font-bold text-primary">{Icon}</p>
                          ) : (
                            <Icon
                              sx={{
                                color: AppColors.PRIMARYCOLOR
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="flex">
              <div className={styles.report__container}>
                <p className="font-semibold text-lg">
                  Total time and cost saved till date
                </p>
                {reportInfo?.map((e, index) => {
                  const isEvenIndex = index % 2 === 0;
                  return (
                    <ReportInfoCard
                      label={
                        isEvenIndex
                          ? "Saved by Recruitbae"
                          : "Total time and cost you saved"
                      }
                      titleHasPrimaryColor={isEvenIndex}
                      hasBottomBorder={isEvenIndex}
                      value={e.value}
                      description={e.description}
                    />
                  );
                })}
              </div>

              <div className="w-1/2">
                {!pieChartCheck ? (
                  <div>
                    <div className={styles.summary__container}>
                      <div className="flex items-center">
                        <p className={styles.summary__title}>
                          {strings.dashboard.summary.title}
                        </p>
                        <p className={styles.summary__month}>Current Month</p>
                      </div>
                      <div className="mb-6">
                        <PieChart width={350} height={280}>
                          <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="name"
                            fill={AppColors.PRIMARYCOLOR}
                            label
                          >
                            {chartData.map((entry, index) => (
                              <Cell
                                key={`${entry.value}${entry.name}${
                                  Math.random() * 10000
                                }`}
                                fill={colors[index]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend height={36} />
                        </PieChart>
                      </div>
                      <p className={styles.summary__chartDescription}>
                        {`All the data mentioned is indicative in % (out of ${dashboardData?.data.card_data.total_candidate} candidates) and based on current month's performance`}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className={styles.report__container}>
            {reportInfo?.map((item) => (
              <ReportInfoCard
                key={item.label}
                label={item.label}
                description={item.description}
                value={item.value}
              />
            ))}
          </div> */}
          {/* {!pieChartCheck ? (
            <div>
              <div className={styles.summary__container}>
                <div className="flex items-center">
                  <p className={styles.summary__title}>
                    {strings.dashboard.summary.title}
                  </p>
                  <p className={styles.summary__month}>Current Month</p>
                </div>
                <div className="mb-6">
                  <PieChart width={350} height={280}>
                    <Pie
                      data={chartData}
                      dataKey="value"
                      nameKey="name"
                      fill={AppColors.PRIMARYCOLOR}
                      label
                    >
                      {chartData.map((entry, index) => (
                        <Cell
                          key={`${entry.value}${entry.name}${
                            Math.random() * 10000
                          }`}
                          fill={colors[index]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend height={36} />
                  </PieChart>
                </div>
                <p className={styles.summary__chartDescription}>
                  {`All the data mentioned is indicative in % (out of ${dashboardData?.data.card_data.total_candidate} candidates) and based on current month's performance`}
                </p>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>

      <div className="flex pt-5">
        <div className={styles.interviews__wrapper}>
          <div className={styles.interviews__innerWrapper}>
            <p className={styles.interviews__title}>
              {strings.dashboard.tableTitle}
            </p>
            <div className="px-5">
              <TableTopTabs
                selectedTab={selectedTab}
                onTabsClick={onTabsClick}
              />
            </div>
            <SizedBox height="32px" />
            <DashboardTable
              assessType={
                selectedTab === 1 ? AssessTypes.NEW : AssessTypes.PENDING
              }
              column={selectedTab === 1 ? column : pendingAssessmentsColumn}
              row={selectedTab === 1 ? todaysAssess : pendingAssessments}
              loading={fetchingMoreRecords}
              metaData={setMetaData}
            />
          </div>
          {!!!todaysAssess.length && selectedTab === 1 ? (
            <div>
              <p className={styles.interviews__noData}>
                {strings.dashboard.noData}
              </p>
            </div>
          ) : (
            !!!pendingAssessments.length &&
            selectedTab === 0 && (
              <div>
                <p className={styles.interviews__noData}>
                  {strings.dashboard.noData}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
