import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const baseURL = process.env.REACT_APP_BASE_URL;

const interceptedInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`
  }
});

const candidateInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json"
  }
});

candidateInstance.interceptors.request.use((config) => {
  //@ts-ignore
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "candidateToken"
  )}`;
  return config;
});

const assembly = axios.create({
  baseURL: "https://api.assemblyai.com/v2",
  headers: {
    authorization: "01a903b2f5b849a49826c12e88602bfb"
  }
});

export {assembly, candidateInstance, interceptedInstance as default};

export const newInstance = axios.create({
  baseURL: process.env.REACT_APP_PYTHON_ENDPOINT,
  params: {},
  data: {},
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 60000
});

newInstance.interceptors.request.use((config) => {
  let token =
    localStorage.getItem("AUTH_TOKEN") ??
    localStorage.getItem("candidateToken");
  if (token !== null) {
    token = `Bearer ${token}`;
    config.headers!.Authorization = `${token}`;
  }
  return config;
});
