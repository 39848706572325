import {Typography} from "@mui/material";
import {Row} from "components/Row";
import SizedBox from "components/SizedBox/SizedBox";
import {AppColors} from "globals/appcolors";
import {CandidateDetails} from "globals/types/APIResponses";
import {CandidateDetailsData} from "globals/types/globalTypes";
import React from "react";
import {useNavigate} from "react-router-dom";
import {AssessmentDatesAndStatus} from "./AssessmentDatesAndStatus";
import {CandidateKPI} from "./KPIs/CandidateKPI";
import {VideoAndScreenshots} from "./VideoAndScreenshots";

interface IProps {
  assessmentDetails: CandidateDetails;
  candidateDetails: CandidateDetailsData | undefined;
  fetchAssessmentDetails: (id: string) => void;
}

export const AssessmentData: React.FC<IProps> = ({
  assessmentDetails,
  candidateDetails,
  fetchAssessmentDetails
}) => {
  const {status} = assessmentDetails.data.assessment;
  const navigate = useNavigate();

  return (
    <section>
      <AssessmentDatesAndStatus
        assessmentDetails={assessmentDetails}
        candidateDetails={candidateDetails}
        fetchAssessmentDetails={fetchAssessmentDetails}
      />

      {status === 11 ? (
        <Typography
          sx={{paddingY: 2, marginTop: 2, textAlign: "center"}}
          fontWeight={"bold"}
          color={AppColors.PRIMARYCOLOR}
          fontSize={20}
          fontStyle={"italic"}
        >
          This assessment is expired
        </Typography>
      ) : (
        <>
          <SizedBox height="40px" />
          <Row>
            <Typography
              fontWeight={"600"}
              color={AppColors.LIGHTBLACK_500}
              fontSize={20}
            >
              Candidate Score
            </Typography>
          </Row>
          <SizedBox height="40px" />

          <section
            className="grid"
            style={{gridTemplateColumns: "33% 34% 33%"}}
          >
            <VideoAndScreenshots assessmentDetails={assessmentDetails} />

            <Row classNames="justify-end !items-start">
              <Row classNames="flex-col items-start w-10/12">
                <CandidateKPI candidateAssessmentDetails={assessmentDetails} />
              </Row>
            </Row>
          </section>
        </>
      )}
    </section>
  );
};
