import InterviewHeader from "components/InterviewHeader";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {memo, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import commonStyles from "./InterviewPreview.module.css";
import styles from "./ThankYouScreen.module.css";

const ThankYouScreen = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem("assessmentid");
  const {mutateAsync: updateAssessment} = useUpdateAssessment(id!);
  useEffect(() => {
    updateAssessment({
      status: 5
    });
  }, []);
  return (
    <div className={`${commonStyles.main_wrapper} h-[100vh]`}>
      <InterviewHeader title="Interview Completed" />
      <div className={styles.wrapper}>
        <h1 className={styles.head}>Thank you</h1>
        <p className={styles.content}>
          <span className="text-[#02BC7D] font-bold">Congrats!</span> Thank you
          for completing the interview! We are currently analyzing your
          performance data and will share your Interview Report Card with you
          via email shortly.
        </p>
        <p className={styles.content}>
          We appreciate your patience, and if you have any questions in the
          meantime, feel free to reach out. at{" "}
          <a href="mailto:help@recruitbae.com" className="font-bold">
            help@recruitbae.com
          </a>
        </p>
        <button
          className="border border-black-150 text-black-150 mt-4 rounded py-2 px-4"
          onClick={() => navigate(`/feedback/${id}`)}
        >
          Share feedback
        </button>
      </div>
    </div>
  );
};

export default memo(ThankYouScreen);
