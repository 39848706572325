import InterviewHeader from "components/InterviewHeader";
import {CountDownTimer} from "components/Timer";
import {Webcam} from "components/Webcam";
import {APIEndPoints} from "globals/constants/endPoints";
import {Images} from "Images";
import axiosPostModule from "network/Post/AxiosPostModule";
import {useEffect, useState} from "react";
import {Spinner} from "react-activity";
import {useReactMediaRecorder} from "react-media-recorder";
import {useParams} from "react-router-dom";
import styles from "./InterviewPreview.module.css";

const Feedback = () => {
  const audioUrl = process.env.REACT_APP_FEEDBACK_AUDIO_URL;
  const {id} = useParams();
  const {stopRecording, mediaBlobUrl, startRecording} = useReactMediaRecorder({
    video: true,
    screen: false,
    audio: true
  });

  const [startFeedbackRecording, setStartFeedbackRecording] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const upload = async (url: string) => {
    setSubmitting(true);
    const response = await fetch(url);
    const blob = await response.blob();
    // Create a new File from the Blob
    const file = new File([blob], "recording.mp4", {type: blob.type});

    const formdata = new FormData();
    formdata.append("file", file);
    await axiosPostModule(APIEndPoints.UPLOAD_FEEDBACK(id!), formdata).finally(
      () => setSubmitting(false)
    );

    setStartFeedbackRecording(false);
    setFeedbackSubmitted(true);
  };

  useEffect(() => {
    if (mediaBlobUrl) {
      upload(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (startFeedbackRecording) {
      const audio = new Audio(audioUrl);
      audio.play();

      startRecording();
    }
  }, [startFeedbackRecording]);

  return (
    <div className={`${styles.main_wrapper} font-urbanist`}>
      <InterviewHeader title="Feedback" />

      {startFeedbackRecording ? (
        <div className="flex flex-col items-center">
          <div className="hidden">
            <CountDownTimer
              time={70}
              onStopTimer={() => {
                stopRecording();
              }}
            />
          </div>
          <Webcam
            className="mt-8 rounded"
            isCentered
            videoConstraints={{height: 500, width: window.innerWidth / 2}}
          />
          <button
            onClick={stopRecording}
            className="mt-4 bg-lightBlack-900 text-white-900 font-bold py-2 px-4 rounded"
            disabled={submitting}
          >
            {submitting ? <Spinner /> : "Submit"}
          </button>
        </div>
      ) : (
        <div className="flex mt-20 mx-12">
          <div>
            <img src={Images.FeedbackImage} alt="" />
          </div>

          <div className="mt-12 max-w-3/5 ml-12">
            {feedbackSubmitted ? (
              <p className="text-4xl font-semibold">
                Thank you for sharing your feedback.
              </p>
            ) : (
              <>
                <p className="text-4xl font-semibold">
                  How was your overall experience with our AI Interviewer?
                </p>
                <p className="mt-4 font-semibold max-w-4/5 mb-4">
                  Share your feedback, and if our product team loves it, you
                  could win Boat Headphones for FREE! 🎉
                </p>

                <img src={Images.BoatHeadphones} alt="" />

                <button
                  onClick={() => setStartFeedbackRecording(true)}
                  className="mt-4 bg-lightBlack-900 text-white-900 py-2 px-4 rounded font-bold"
                >
                  Start feedback
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
