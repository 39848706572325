import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNewRounded";
import {AppColors} from "globals/appcolors";
import {CandidateLinks} from "globals/constants/routeLinks";
import {postGoogleAnalyticsEvent} from "globals/helpers";
import React, {CSSProperties} from "react";
import {useNavigate} from "react-router-dom";
import {Column, Row, useTable} from "react-table";
import styles from "./UserTableStyle.module.scss";

interface TableProps {
  columns: Array<Column>;
  data: Array<any>;
  className?: CSSProperties | undefined;
}

interface Props {
  column: any;
  row: Array<any>;
}

const Table: React.FC<TableProps> = ({columns, data}) => {
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("Candidate");
  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      //@ts-ignore
      columns,
      data
    });

  const openCandidateDetails = (row: Row<{}>) => {
    eventTracker(
      "Candidate Details viewed",
      "Fetching all assessments of a candidate"
    );
    navigate(
      // @ts-ignore
      `/${CandidateLinks.Candidate_Detail}?candidate=${row?.original?.candidate_id}`
    );
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            style={{background: "#f4f4f4"}}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  background: "#f4f4f4",
                  justifyContent: "flex-start"
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{border: "none", borderBottom: "1px solid #e4e4e4"}}
            >
              {row.cells.map((cell, index) => {
                const isCompleted =
                  cell.row.original.interviewStatus === "Completed";
                return (
                  <td
                    key={index.toString()}
                    style={{
                      width: "80px",
                      justifyContent: "flex-start"
                    }}
                  >
                    <div className="flex w-100 px-3 justify-start">
                      <p
                        style={{
                          color:
                            index === 3 || index === 0
                              ? cell.value
                                ? AppColors.PRIMARYCOLOR
                                : AppColors.GREY
                              : index === 2
                              ? row.original.interviewStatus !== "Completed"
                                ? "#B59506"
                                : "#2FAF5B"
                              : index === 4
                              ? AppColors.PRIMARYCOLOR
                              : AppColors.BLACK,
                          cursor:
                            index === 0
                              ? "pointer"
                              : index === 3 && cell.value && isCompleted
                              ? "pointer"
                              : index === 5 && cell.value
                              ? "pointer"
                              : "not-allowed",
                          textAlign: index === 4 ? "center" : "left",
                          fontSize: 14,
                          fontWeight: "600",
                          padding:
                            index === 2 || index === 4 ? ".2rem 1rem" : 0,
                          borderRadius: index === 2 || index === 4 ? 5 : 0,
                          flex: index === 4 || index === 2 ? 0.5 : 1,
                          background:
                            index === 2
                              ? row.original.interviewStatus !== "Completed"
                                ? "#fff7d4"
                                : "#edfbf1"
                              : index === 4
                              ? `${AppColors.PRIMARYCOLOR}20`
                              : "transparent"
                        }}
                        onClick={() =>
                          index === 5
                            ? window.open(cell.value)
                            : index === 0
                            ? openCandidateDetails(row)
                            : index === 3 &&
                              cell.value &&
                              isCompleted &&
                              window.open(cell.value)
                        }
                        className="text-ellipsis whitespace-nowrap overflow-hidden"
                      >
                        {index === 5 ? (
                          cell.value ? (
                            <>
                              <span>
                                <OpenInNewIcon
                                  sx={{color: AppColors.PRIMARYCOLOR}}
                                />
                              </span>
                              <span className="text-primary">Feedback</span>
                            </>
                          ) : (
                            "N.A."
                          )
                        ) : index === 4 ? (
                          `${cell.value ?? 0}%`
                        ) : index === 3 ? (
                          <>
                            <span>
                              <FileDownloadOutlinedIcon
                                sx={{opacity: isCompleted ? 1 : 0.6}}
                              />
                            </span>
                            <span style={{opacity: isCompleted ? 1 : 0.6}}>
                              Download report card
                            </span>
                          </>
                        ) : (
                          cell.value
                        )}
                      </p>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const UserTable: React.FC<Props> = ({column, row}) => {
  type tableRow = {
    id: string;
    candidateName: string;
    email: string;
    interviewStatus: string;
    candidate_id: number;
    reportCard: string;
  };

  const columns: Array<Column> = React.useMemo(() => [...column], []);
  const data: Array<tableRow> = React.useMemo(() => [...row], [row]);

  return (
    <div className={styles.react_table}>
      <Table columns={columns} data={data} className={styles.react_table} />
    </div>
  );
};

export default UserTable;
