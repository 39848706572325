import {Typography} from "@mui/material";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Provider} from "@rollbar/react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "App.css";
import {AppCommonDataProvider} from "components/AppCommonDataProvider";
import {ChatProvider} from "components/ChatProvider";
import LoginCard from "components/Containers/Cards/LoginCard/LoginCard";
import MainContainer from "components/Containers/MainContainer/MainContainer";
import CreateAssessmentProvider from "components/CreateAssessmentProvider";
import AppNav from "components/NavBars/AppNav/AppNav";
import {Row} from "components/Row";
import {AppColors} from "globals/appcolors";
import {CandidateLinks, RouteLinks} from "globals/constants/routeLinks";
import strings from "globals/constants/strings.json";
import {checkIfMobileDevice, initMixpanel} from "globals/helpers";
import React, {useEffect, useState} from "react";
import CacheBuster from "react-cache-buster";
import GoogleAnalytics from "react-ga";
import "react-responsive-modal/styles.css";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Feedback from "routes/Interview/Feedback";
import InterviewPing from "routes/Interview/InterviewPing";
import InterviewPreview from "routes/Interview/InterviewPreview";
import {SecondRoundInterview} from "routes/Interview/SecondRoundInterview";
import ThankYouScreen from "routes/Interview/ThankYouScreen";
import LandingPageV2 from "routes/LandingPageV2";
import Profile from "routes/Profile/Profile";
import RoleManagement from "routes/Profile/RoleManagement";
import PasswordChange from "routes/ResetPassword/PasswordChange";
import ResetPassword from "routes/ResetPassword/ResetPassword";
import AssessmentPage from "routes/assessment/AssessmentPage/AssessmentPage";
import CandidateDetail from "routes/candidate/CandidateDetail/CandidateDetail";
import QNA from "routes/candidate/CandidateDetail/QNA";
import ReportCard from "routes/candidate/CandidateDetail/ReportCard";
import Candidate from "routes/candidate/CandidateHome/Candidate";
import Dashboard from "routes/dashboard/DashboardHome/Dashboard";
import ShortenedInterview from "routes/interview-shorthand";
import {CandidateLogin} from "routes/login/CandidateLogin/CandidateLogin";
import NotFound from "routes/notFound/NotFound";
import OneUpAssessment from "routes/one-up-assessment";
import Screenshots from "routes/screenshots";
import packageData from "../package.json";

const App: React.FC = () => {
  const materialTheme = createTheme({
    typography: {
      fontFamily: ["Mulish"].join(",")
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            background: AppColors.PRIMARYCOLOR,
            color: AppColors.WHITE,
            textTransform: "inherit",
            "&:hover": {
              background: AppColors.PRIMARYCOLOR
            },
            "&:disabled": {
              background: AppColors.GREY,
              color: "white"
            }
          }
        }
      }
    }
  });

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    initMixpanel();
    GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY!);
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const queryClient = new QueryClient();

  const isMobileDevice = checkIfMobileDevice();

  const MobileView: React.FC = () => {
    return (
      <Row classNames="bg-white-900 h-screen justify-center">
        <section
          className="shadow-all-around p-6 rounded-xl mobile__viewCard"
          style={{maxWidth: "80vw", minWidth: "80vw"}}
        >
          <Typography
            variant="h3"
            sx={{fontWeight: "500", color: AppColors.PRIMARYCOLOR}}
          >
            {strings.uh_oh}
          </Typography>
          <Typography sx={{marginTop: 2, textAlign: "justify"}}>
            {strings.mobile_device}
          </Typography>
        </section>
      </Row>
    );
  };

  if (!isMobileDevice)
    return (
      <CacheBuster
        currentVersion={packageData.version}
        isEnabled={process.env.NODE_ENV === "production"}
        isVerboseMode={false}
        loadingComponent={<></>}
      >
        <Provider
          config={{
            accessToken: process.env.REACT_APP_ROLLBAR_ID,
            environment: "testenv",
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled: process.env.REACT_APP_ENABLE_ROLLBAR === "true",
            payload: {
              client: {
                javascript: {
                  source_map_enabled: true
                }
              }
            }
          }}
        >
          <AppCommonDataProvider>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={materialTheme}>
                  <div className="App">
                    <Router>
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <AppNav />
                        <MainContainer>
                          <Routes>
                            <Route path="/" element={<LandingPageV2 />} />
                            <Route
                              path={RouteLinks.Dashboard}
                              element={<Dashboard />}
                            />
                            <Route
                              path={RouteLinks.Login}
                              element={<LoginCard />}
                            />
                            <Route
                              path={RouteLinks.Reset_Password}
                              element={<ResetPassword />}
                            />
                            <Route
                              path={RouteLinks.Password_Change}
                              element={<PasswordChange />}
                            />
                            <Route
                              path={RouteLinks.Assessment_Page}
                              element={
                                <CreateAssessmentProvider>
                                  <AssessmentPage />
                                </CreateAssessmentProvider>
                              }
                            />
                            <Route
                              path={RouteLinks.Candidate}
                              element={<Candidate />}
                            />

                            <Route
                              path={RouteLinks.Interview_Preview}
                              element={<InterviewPreview />}
                            />
                            <Route
                              path={RouteLinks.Interview_Ping}
                              element={<InterviewPing />}
                            />
                            <Route
                              path={RouteLinks.Interview_Finish}
                              element={<ThankYouScreen />}
                            />
                            <Route path="feedback/:id" element={<Feedback />} />
                            <Route
                              path={`${RouteLinks.Interview_Round_2}`}
                              element={
                                <ChatProvider>
                                  <SecondRoundInterview />
                                </ChatProvider>
                              }
                            />
                            <Route
                              path={CandidateLinks.Candidate_Detail}
                              element={<CandidateDetail />}
                            />
                            <Route path={"*"} element={<NotFound />} />
                            <Route
                              path={`${RouteLinks.Profile}`}
                              element={<Profile />}
                            />
                            <Route
                              path={`${RouteLinks.RoleManagement}`}
                              element={<RoleManagement />}
                            />
                            <Route
                              path={`${RouteLinks.Candidate_Login}`}
                              element={<CandidateLogin />}
                            />
                            <Route
                              path={RouteLinks.ReportCard}
                              element={<ReportCard />}
                            />
                            <Route path={"qna"} element={<QNA />} />
                            <Route
                              path={`${RouteLinks.Screenshots}`}
                              element={<Screenshots />}
                            />
                            <Route
                              path={`${RouteLinks.Create_Oneup_Assessment}/:skill`}
                              element={<OneUpAssessment />}
                            />
                            <Route
                              path={`sh/:id`}
                              element={<ShortenedInterview />}
                            />
                          </Routes>
                        </MainContainer>
                        <ToastContainer />
                      </div>
                    </Router>
                  </div>
                </ThemeProvider>
              </LocalizationProvider>
            </QueryClientProvider>
          </AppCommonDataProvider>
        </Provider>
      </CacheBuster>
    );
  else return <MobileView />;
};

export default App;
