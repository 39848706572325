import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Select from "components/Inputs/Select/CustomSelect";
import InterviewHeader from "components/InterviewHeader";
import {Field, Form, Formik} from "formik";
import {APIEndPoints} from "globals/constants/endPoints";
import {microCopies} from "globals/constants/microcopies";
import {CreateAsseessmentFields, SelectOption} from "globals/types/globalTypes";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import {useUploadScreenshot} from "hooks/interview/useUploadScreenshot";
import {Response as SkillsResponse} from "hooks/skills/useFetchSkills";
import {Images} from "Images";
import moment from "moment";
import axiosGetModule from "network/Get/AxiosGetModule";
import axiosPostModule from "network/Post/AxiosPostModule";
import {useEffect, useRef, useState} from "react";
import {Spinner} from "react-activity";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import * as yup from "yup";
import styles from "./style.module.css";

const OneUpAssessment = () => {
  const {skill} = useParams();
  const navigate = useNavigate();
  const {getMicrocopy} = useAppCommonDataProvider();

  const [skillSet, setSkillSet] = useState<SelectOption[]>([]);
  const [formValues, setFormValues] = useState<{
    email: string;
    name: string;
    expertise: SelectOption | null;
    file: File | null;
  }>({
    email: "",
    name: "",
    expertise: null,
    file: null
  });
  const [creating, setCreating] = useState<boolean>(false);

  const {mutateAsync: createUserAssessment} = useCreateAssessment();
  const {mutateAsync: uploadFile} = useUploadScreenshot({
    isResume: true
  });

  const fileRef = useRef<HTMLInputElement | null>(null);

  const fetchskills = async () => {
    const loginData = await axiosPostModule(APIEndPoints.LOGIN_NODE, {
      email: "bindu@shethink.in",
      password: "Shethink@123",
      device_name: "macintosh"
    });
    localStorage.setItem("AUTH_TOKEN", loginData.data.accessToken);
    localStorage.setItem("USER_DETAILS", JSON.stringify(loginData.data));

    setTimeout(() => {
      axiosGetModule(APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE)
        .then((res: SkillsResponse) => {
          const skills = res.data
            .filter(
              (ele) =>
                ele.skill.toLowerCase().split(" ").length === 1 &&
                ele.skill.toLowerCase().includes(skill!)
            )
            .map((ele) => {
              return {
                label: ele.skill,
                value: ele.id
              };
            });

          setSkillSet(skills);
          setFormValues((prev) => ({
            ...prev,
            expertise: skills[0]
          }));
        })
        .catch((err) => console.log(err));
    }, 500);
  };

  useEffect(() => {
    fetchskills();
  }, []);

  const validate = yup.lazy(() =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, "Too Short!")
        .max(150, "Too Long!")
        .required("Field Name is required"),
      email: yup
        .string()
        .required("Field email is required")
        .email("Enter a vaild email"),
      file: yup
        .mixed()
        .required("A file is required")
        .test(
          "fileSize",
          "File size is too large. Maximum size is 5MB.",
          (value) => value && value.size <= 5 * 1024 * 1024
        )
    })
  );

  const handleSubmit = async () => {
    try {
      setCreating(true);
      const {name, email, expertise, file} = formValues;
      const [first_name, last_name] = name.split(" ");
      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);
      const skill_ids = [expertise?.value];

      const formData = new FormData();
      formData.append("file", file!);
      const {
        data: {
          data: {path: resume_url}
        }
      } = await uploadFile(formData);

      const payload: CreateAsseessmentFields = {
        resume_url,
        scheduled_date: moment().format("YYYY-MM-DD"),
        candidate_name: first_name,
        candidate_last_name: last_name,
        email,
        experience_duration: "3",
        organization_id: userOrgId.user.teams[0].organization.id,
        skills_ids: skill_ids,
        responseURL: true,
        assessment_name: `${expertise?.label} assessment`
      };

      createUserAssessment(payload)
        .then((res) => {
          if (res) {
            if (res.data.success) {
              const string = res.data.data.url;

              const splitted = string.split("payload=");
              const newString = `${splitted[0]}payload=${splitted[1]}`;

              const navigatingUrl = newString.split(
                process.env.REACT_APP_DOMAIN!
              )[1];
              navigate(navigatingUrl);
            }
          }
        })
        .finally(() => setCreating(false));
    } catch (e) {
      console.log("eee", e);
    }
  };

  return (
    <div className={styles.main_wrapper}>
      <InterviewHeader
        title={getMicrocopy(microCopies.INTERVIEW_UPLOAD_RESUME_HEADING)}
      />
      <div className={styles.main_container}>
        <Formik
          validationSchema={validate}
          initialValues={formValues}
          onSubmit={handleSubmit}
        >
          {({errors, touched, values, setFieldValue, setFieldTouched}) => {
            return (
              <Form>
                <div className={styles.form_wrapper}>
                  <div className={styles.row}>
                    <div className="w-1/2">
                      <label className={styles.label}>Name*</label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className={styles.input}
                        value={formValues.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("name", e.target.value);
                          setFormValues((prev) => {
                            return {
                              ...prev,
                              name: e.target.value
                            };
                          });
                        }}
                      />
                      {errors?.name && touched.name ? (
                        <p className={styles.err}>{errors?.name}</p>
                      ) : null}
                    </div>
                    <div className="w-1/2">
                      <label className={styles.label}>Email*</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="abc@mail.com"
                        className={styles.input}
                        value={formValues.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("email", e.target.value);
                          setFormValues((prev) => {
                            return {
                              ...prev,
                              email: e.target.value
                            };
                          });
                        }}
                      />
                      {errors?.email && touched.email ? (
                        <p className={styles.err}>{errors?.email}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className="w-1/2">
                      <label className={styles.label}>
                        Field of Expertise*
                      </label>
                      <Select
                        value={formValues.expertise}
                        options={skillSet}
                        disabled
                        onBlur={() => {
                          setFieldTouched("expertise", true);
                        }}
                        className={styles.dropdown}
                        placeholder={"Tech"}
                        isDisabled
                      />
                      {errors?.expertise && touched.expertise ? (
                        <p className={styles.err}>
                          {errors?.expertise as string}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className={"w-full"}>
                    <div
                      className={`${styles.file_upload_container}`}
                      onClick={() => fileRef.current?.click()}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        e.preventDefault();
                        if (e.dataTransfer.files.length > 0) {
                          setFormValues((prev) => ({
                            ...prev,
                            file: e.dataTransfer.files[0]
                          }));
                          setFieldValue("file", e.dataTransfer.files[0]);
                          window.focus();
                          toast.success("File attached successfully");
                        }
                      }}
                    >
                      <img
                        src={Images.FileUploadArea}
                        className="absolute w-full h-full"
                      />
                      <input
                        onBlur={() => {
                          setFieldTouched("file", true);
                        }}
                        onChange={(e) => {
                          const selectedFile = e.target.files
                            ? e.target.files[0]
                            : null;

                          setFormValues((prev) => {
                            return {
                              ...prev,
                              file: selectedFile
                            };
                          });
                          setFieldValue("file", selectedFile);
                          toast.success("File attached successfully");
                        }}
                        accept=".doc, .docx, .pdf"
                        ref={fileRef}
                        type="file"
                        style={{display: "none", width: "100%", height: "100%"}}
                      />
                      {formValues?.file ? (
                        <p className={styles.fileName}>
                          {formValues?.file?.name}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    {errors?.file && touched?.file ? (
                      <p className={styles.err}>{errors?.file}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    disabled={creating}
                    type={"submit"}
                    className={styles.btn}
                  >
                    {creating ? <Spinner /> : "Create"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default OneUpAssessment;
