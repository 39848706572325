import {useQuery, useQueryClient, UseQueryResult} from "@tanstack/react-query";
import {APIEndPoints} from "globals/constants/endPoints";
import {InterviewAssessmentDetails} from "globals/types/globalTypes";
import axiosGetModule from "network/Get/AxiosGetModule";

export const useGetAssessmentDetailsByEmail = (
  email: string
): UseQueryResult<{data: {assessments: InterviewAssessmentDetails[]}}> => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["assessmentDetialsByEmail"],
    queryFn: async () => {
      return axiosGetModule(APIEndPoints.GET_ASSESSMENTS_BY_EMAIL(email)).then(
        (res) => {
          queryClient.invalidateQueries(["assessmentDetailsByEmail"]);
          return res;
        }
      );
    },
    enabled: false,
    retry: false
  });
};
