// @ts-nocheck
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {useChat} from "components/ChatProvider";
import WarningPopup from "components/InterviewWarningPopup";
import PermissionsErrorModal from "components/PermissionsErrorModal";
import {SecondRoundCompleted} from "components/Popups/SecondRoundCompleted";
import {SecondRoundFinishConfirmation} from "components/Popups/SecondRoundFinishConfirmation";
import {Row} from "components/Row";
import {AppColors} from "globals/appcolors";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {RouteLinks} from "globals/constants/routeLinks";
import {
  getElementDimensions,
  postMixPanelEvent,
  strToBool
} from "globals/helpers";
import {
  DialogType,
  FullScreenParameters,
  SecondRoundQuestion,
  SecondRoundQuestionType
} from "globals/types/globalTypes";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from "mic-check";
import moment, {Moment} from "moment";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Bounce, Spinner} from "react-activity";
import {useFullScreenHandle} from "react-full-screen";
import {StatusMessages, useReactMediaRecorder} from "react-media-recorder";
import Modal from "react-responsive-modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SecondRoundInterviewFlow} from "./SecondRoundInterviewFlow";

export const SecondRoundInterview: React.FC = () => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");

  const voice = new SpeechSynthesisUtterance();

  const previousRecorderStatus = useRef<StatusMessages | null>(null);
  const tabSwitchCount = useRef<number>(0);
  const tabSwitchStartTime = useRef<Moment | null>(null);
  const timerId = useRef<NodeJS.Timer | undefined>(undefined);

  const {startRecording, mediaBlobUrl, stopRecording, status} =
    useReactMediaRecorder({
      video: false,
      screen: true,
      audio: true
    });
  const handle = useFullScreenHandle();
  const navigate = useNavigate();

  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );
  const {setPermissions, permissions, setInterview_details, interview_details} =
    useAppCommonDataProvider();
  const {audio} = useChat();

  const [videoConstraints, setVideoConstraints] =
    useState<MediaTrackConstraints>({
      width: 0,
      height: 0
    });
  const [screenShareDenied, setScreenShareDenied] = useState<boolean>(false);
  const [fullScreenParameters, setFullScreenParameters] =
    useState<FullScreenParameters>({
      didUserPrompt: false,
      showModal: false,
      changeCount: 0
    });
  const [userSwitchedTabs, setUserSwitchedTabs] = useState<boolean>(false);
  const [
    countdownRemainingTimeAfterTabSwitch,
    setCountdownRemainingTimeAfterTabSwitch
  ] = useState<number>(0);
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false);
  const [isTestFinishing, setIsTestFinishing] = useState<boolean | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<SecondRoundQuestion[]>([]);
  const [renderable, setRenderable] = useState<boolean>(true);
  const [inMiddleOfTheAssessment, setInMiddleOfTheAssessment] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<DialogType | null>(
    null
  );
  const [presetting, setPresetting] = useState<boolean>(true);
  const [systemMismatch, setSystemMismatch] = useState<boolean>(false);
  const [isAssessmentComplete, setIsAssessmentComplete] =
    useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [currentBehaviourQuestionIndex, setCurrentBehaviourQuestionIndex] =
    useState<number>(1);
  const [currentCodingQuestionIndex, setCurrentCodingQuestionIndex] =
    useState<number>(1);
  const [showTabSwitchText, setShowTabSwitchText] = useState<boolean>(false);

  const showDialogRef = useRef<DialogType | null>(permissionError);
  showDialogRef.current = permissionError;

  function handleScreenShareDenied() {
    if (
      (previousRecorderStatus.current === "acquiring_media" &&
        status === "idle") ||
      (status === "stopped" && isTestFinishing === false)
    ) {
      setScreenShareDenied(true);
    }
    if (
      previousRecorderStatus.current === "stopped" &&
      status === "acquiring_media"
    ) {
      setInMiddleOfTheAssessment(true);
    }
    if (status === "recording") {
      setScreenShareDenied(false);
      setIsTestFinishing(false);
      resizeVideo();
    }

    previousRecorderStatus.current = status;
  }

  function restartSession() {
    startRecording();
  }

  function finishTest() {
    updateAssessment({
      status: 5
    }).then(() => {
      localStorage.setItem(
        "assessmentid",
        interview_details?.data.assessment.id
      );
      setLoading(false);
      postMixPanelEvent("Interview completed");
      navigate(`/${RouteLinks.Interview_Finish}`);
    });
  }

  function handleTabSwitch(e: Event) {
    if (document.visibilityState === "hidden") {
      if (tabSwitchCount.current === 4) {
        setUserSwitchedTabs(true);
        startUpload();
      } else {
        e.preventDefault();
        window.dispatchEvent(new Event("tab_switched"));

        if (!showTabSwitchText) {
          setShowTabSwitchText(true);
        }

        voice.text =
          "Please return to your screen before the assessment is automatically finished";
        window.speechSynthesis.speak(voice);
        setFullScreenParameters({
          showModal: false,
          didUserPrompt: true,
          changeCount: fullScreenParameters.changeCount
        });
        handle.exit();
        tabSwitchCount.current += 1;

        updateAssessment({tab_switches: tabSwitchCount.current}).finally(() => {
          getAssessmentDetails().then((res) => {
            setInterview_details?.(res.data);
          });
        });
        setUserSwitchedTabs(true);
        tabSwitchStartTime.current = moment();
        startCountdown();
      }
    }
  }

  useEffect(() => {
    if (userSwitchedTabs && audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [userSwitchedTabs, audio]);

  function startCountdown() {
    timerId.current = setInterval(() => {
      const remainingTime = moment().diff(
        tabSwitchStartTime.current,
        "seconds"
      );
      setCountdownRemainingTimeAfterTabSwitch(remainingTime);
      if (remainingTime === 45) {
        clearInterval(timerId.current);
        startUpload();
      }
    }, 1000);
  }

  function handleTabSwitchModalClose() {
    enterFullScreen();
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
    window.dispatchEvent(new Event("tab_switch_reverted"));
  }

  function enterFullScreen() {
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
    handle.enter();
    window.dispatchEvent(new Event("entered_full_screen"));
    setFullScreenParameters({
      didUserPrompt: false,
      showModal: false,
      changeCount: fullScreenParameters.changeCount + 1
    });
  }

  function handleFinishConfirmationClose() {
    handle.enter();
    setShowFinishModal(false);
  }

  function handleResize() {
    resizeVideo();
  }

  function resizeVideo() {
    const dimensions = getElementDimensions(
      document.getElementsByClassName("webcam__container")[0]
    );

    if (dimensions !== null) {
      const {height, width} = dimensions;
      setVideoConstraints({width, height: height / 2});
    }
  }

  async function uploadSessionForProcessing(url?: string) {
    setLoading(true);

    // const response = await fetch(url);
    // const blob = await response.blob();
    // // Create a new File from the Blob
    // const file = new File([blob], "recording.mp4", {type: blob.type});
    // const s3Response = await s3client.uploadFile(
    //   file,
    //   `${interview_details?.data.assessment.candidate.user.first_name}-${interview_details?.data.assessment.candidate.user.last_name}'s-interview-recording`
    // );

    // if (s3Response.status === 204) {
    //   const data = new FormData();
    //   data.append("s3_url", s3Response.location);
    //   data.append(
    //     "documentId",
    //     interview_details?.data.assessment.main_question_id!
    //   );

    //   newInstance.post("/upload-video", data);
    // }
    finishTest();
  }

  function startUpload() {
    setIsTestFinishing(true);
    stopRecording();
  }

  function checkForExplanationDialog() {
    if (
      (!permissions?.camera || !permissions.microphone) &&
      showDialogRef.current === null
    )
      setPermissionError(DialogType.explanation);
  }

  async function checkPermissions() {
    if (!("navigator" in window)) {
      setIsPermissionsModalOpen(false);
      setRenderable(false);
    } else {
      requestMediaPermissions()
        .then(() => {
          setIsPermissionsModalOpen(false);
          setPermissions?.({
            ...permissions,
            camera: true,
            microphone: true
          });
        })
        .catch((e: MediaPermissionsError) => {
          setIsPermissionsModalOpen(true);
          const {type} = e;
          if (type === MediaPermissionsErrorType.SystemPermissionDenied)
            setPermissionError(DialogType.systemDenied);
          else if (type === MediaPermissionsErrorType.UserPermissionDenied)
            setPermissionError(DialogType.userDenied);
          else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource)
            setPermissionError(DialogType.trackError);
          else setPermissionError(DialogType.explanation);
        })
        .finally(() => setPresetting(false));

      setTimeout(() => checkForExplanationDialog(), 500);
    }
  }

  const handleFullScreenChange = useCallback(
    (state: boolean) => {
      if (!state && !fullScreenParameters.didUserPrompt)
        setFullScreenParameters({
          didUserPrompt: true,
          showModal: true,
          changeCount:
            fullScreenParameters.changeCount &&
            fullScreenParameters.changeCount + 1
        });
      else
        setFullScreenParameters({
          didUserPrompt: false,
          showModal: false,
          changeCount: fullScreenParameters.changeCount + 1
        });
    },
    [handle]
  );

  useEffect(() => {
    checkPermissions();
  }, []);

  useEffect(() => {
    if (isTestFinishing === true) {
      uploadSessionForProcessing(mediaBlobUrl);
    }
  }, [isTestFinishing]);

  useEffect(() => {
    handleScreenShareDenied();
  }, [status]);

  useEffect(() => {
    if (
      presetting === false &&
      isPermissionsModalOpen === false &&
      systemMismatch === false
    ) {
      const questions = localStorage.getItem(COOKIESKEYS.ROUND_TWO_QUESTIONS);
      if (questions === null) {
        setSystemMismatch(true);
      } else {
        getAssessmentDetails().then((res) => {
          if (res.data) {
            console.log("i have data", res.data.data.assessment);
            setInterview_details?.(res.data);
            const {data} = res.data;
            const {assessment} = data;
            const {status} = assessment;

            if (status === 5) setIsAssessmentComplete(true);

            if (status === 2 || status === 3 || status === 4) {
              const {
                data: {
                  data: {
                    assessment: {
                      assessment_questions: allQuestions,
                      total_tab_switches
                    }
                  }
                }
              } = res;
              tabSwitchCount.current = total_tab_switches;

              if (total_tab_switches && total_tab_switches > 0) {
                setShowTabSwitchText(true);
              }

              const priority: Record<SecondRoundQuestionType, number> = {
                greetings: 1,
                Technical_analysis: 2,
                behavioral_analysis: 3,
                coding_analysis: 4
              };

              allQuestions.sort(
                (a, b) => priority[a.overall_type] - priority[b.overall_type]
              );

              let technicalQuestions = allQuestions.filter(
                (e) => e.overall_type === "Technical_analysis"
              );
              let behaviourQuestions = allQuestions.filter(
                (e) => e.overall_type === "behavioral_analysis"
              );
              let codingQuestions = allQuestions.filter(
                (e) => e.overall_type === "coding_analysis"
              );
              const greetingsQuestions = allQuestions.filter(
                (e) => e.overall_type === "greetings"
              );

              if (technicalQuestions.length > 14) {
                technicalQuestions = technicalQuestions.slice(0, 14);
              }
              if (behaviourQuestions.length > 5) {
                behaviourQuestions = behaviourQuestions.slice(0, 5);
              }
              if (codingQuestions.length > 3) {
                codingQuestions = codingQuestions.slice(0, 3);
              }

              const newQuestions = [
                ...greetingsQuestions,
                ...technicalQuestions,
                ...behaviourQuestions.sort(
                  (a, b) => Number(b.is_answered) - Number(a.is_answered)
                ),
                ...codingQuestions
              ];

              if (status === 2) {
                const numberOfGreetingQuestions = newQuestions.filter(
                  (e) => e.overall_type === "greetings"
                ).length;
                const numberOfGreetingQuestionsAnswered = newQuestions.filter(
                  (e) =>
                    e.overall_type === "greetings" && e.question_answer !== ""
                ).length;

                if (newQuestions.length > 4) {
                  const temp = newQuestions[3];
                  newQuestions[3] = newQuestions[4];
                  newQuestions[4] = temp;
                }
                setQuestions(newQuestions);
                const lastAnsweredIndex =
                  numberOfGreetingQuestions !==
                  numberOfGreetingQuestionsAnswered
                    ? 0
                    : newQuestions.findIndex(
                        (question) =>
                          question.question_answer === "" &&
                          question.overall_type === "Technical_analysis"
                      );
                setCurrentQuestionIndex(lastAnsweredIndex);
              }

              if (status === 3) {
                const lastAnsweredQuestionIndex = newQuestions.findLastIndex(
                  (question) => question.is_answered
                );
                const firstBehaviourQuestionIndex = newQuestions.findIndex(
                  (question) => question.overall_type === "behavioral_analysis"
                );

                setCurrentQuestionIndex(
                  lastAnsweredQuestionIndex === -1
                    ? firstBehaviourQuestionIndex
                    : lastAnsweredQuestionIndex + 2
                );
                setCurrentBehaviourQuestionIndex(
                  lastAnsweredQuestionIndex === -1
                    ? 1
                    : lastAnsweredQuestionIndex + 1 - 15
                );
                setQuestions(newQuestions);
              }

              if (status === 4) {
                const lastAnsweredQuestionIndex = newQuestions.findIndex(
                  (question) =>
                    question.overall_type === "coding_analysis" &&
                    question.question_answer === ""
                );
                const firstCodingQuestionIndex = newQuestions.findIndex(
                  (question) => question.overall_type === "coding_analysis"
                );

                setQuestions(newQuestions);

                if (firstCodingQuestionIndex === lastAnsweredQuestionIndex) {
                  setCurrentQuestionIndex(lastAnsweredQuestionIndex);
                  setCurrentCodingQuestionIndex(1);
                } else {
                  setCurrentQuestionIndex(lastAnsweredQuestionIndex);
                  setCurrentCodingQuestionIndex(
                    lastAnsweredQuestionIndex - firstCodingQuestionIndex + 1
                  );
                }
              }

              setRenderable(true);
              startRecording();
            }
          }
        });
        strToBool(process.env.REACT_APP_ENABLE_TAB_SWITCH!) &&
          document.addEventListener("visibilitychange", handleTabSwitch);
        window.addEventListener("resize", handleResize);
      }
    }

    return () => {
      stopRecording();
      window.onbeforeunload = () => null;
      strToBool(process.env.REACT_APP_ENABLE_TAB_SWITCH!) &&
        document.removeEventListener("visibilitychange", handleTabSwitch);
      window.removeEventListener("resize", handleResize);
      window.speechSynthesis.cancel();
    };
  }, [presetting, isPermissionsModalOpen, systemMismatch]);

  if (isAssessmentComplete)
    return (
      <SecondRoundCompleted
        open
        onClose={() => {
          window.open("about:blank", "_self");
          window.close();
        }}
      />
    );
  else if (renderable) {
    if (presetting)
      return (
        <Row classNames="h-screen w-screen justify-center">
          <Bounce color={AppColors.PRIMARYCOLOR} size={40} />
        </Row>
      );
    else {
      if (isPermissionsModalOpen)
        return (
          <PermissionsErrorModal
            isOpen={isPermissionsModalOpen}
            errorType={permissionError}
          />
        );

      if (systemMismatch)
        return (
          <WarningPopup
            type="systemMismatch"
            isOpen={systemMismatch}
            onClose={() => {}}
          />
        );
      else {
        return (
          <>
            {screenShareDenied && !inMiddleOfTheAssessment && (
              <WarningPopup
                isOpen={screenShareDenied}
                onClose={() => {
                  setScreenShareDenied(false);
                  restartSession();
                }}
                type="screenShareStopped"
              />
            )}

            {loading && (
              <Modal
                open
                onClose={() => {}}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
                    paddingBlock: "2rem",
                    paddingInline: "4rem",
                    borderRadius: 6
                  }
                }}
              >
                <div className="flex items-center">
                  <Spinner size={30} color={AppColors.REPORTCARD_SECONDARY} />
                  <p className="ml-4 font-bold text-xl">Finishing your test</p>
                </div>
              </Modal>
            )}

            {status === "recording" ? (
              <>
                <SecondRoundInterviewFlow
                  videoConstraints={videoConstraints}
                  fullScreenHandle={handle}
                  questions={questions}
                  showTimer={isTestFinishing}
                  isTestFinishing={isTestFinishing}
                  handleFullScreenChange={handleFullScreenChange}
                  currentQuestionIndex={currentQuestionIndex}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                  currentBehaviourQuestionIndex={currentBehaviourQuestionIndex}
                  setCurrentBehaviourQuestionIndex={
                    setCurrentBehaviourQuestionIndex
                  }
                  currentCodingQuestionIndex={currentCodingQuestionIndex}
                  setCurrentCodingQuestionIndex={setCurrentCodingQuestionIndex}
                  onPressEnd={() => {
                    handle.exit();
                    setFullScreenParameters({
                      changeCount: fullScreenParameters.changeCount,
                      didUserPrompt: true,
                      showModal: false
                    });
                    setShowFinishModal(!showFinishModal);
                  }}
                  stopRecording={startUpload}
                  showTabSwitchText={showTabSwitchText}
                />

                {screenShareDenied && inMiddleOfTheAssessment && (
                  <WarningPopup
                    isOpen={screenShareDenied}
                    onClose={() => {
                      setScreenShareDenied(false);
                      restartSession();
                    }}
                    type="screenShareStopped"
                  />
                )}

                {userSwitchedTabs && (
                  <WarningPopup
                    isOpen={userSwitchedTabs}
                    type="tabSwitch"
                    onClose={handleTabSwitchModalClose}
                    switchCount={tabSwitchCount.current}
                    remainingTime={countdownRemainingTimeAfterTabSwitch}
                  />
                )}

                {fullScreenParameters.didUserPrompt &&
                  fullScreenParameters.showModal && (
                    <WarningPopup
                      type="enterFullScreen"
                      isOpen={
                        fullScreenParameters.didUserPrompt &&
                        fullScreenParameters.showModal
                      }
                      onClose={enterFullScreen}
                    />
                  )}

                {showFinishModal === true && (
                  <SecondRoundFinishConfirmation
                    open={showFinishModal}
                    onClose={handleFinishConfirmationClose}
                    stopRecording={startUpload}
                  />
                )}
              </>
            ) : null}
          </>
        );
      }
    }
  } else return null;
};
