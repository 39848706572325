import {useMutation, useQueryClient} from "@tanstack/react-query";
import {candidateInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";

export function useUpdateSecondRoundResponse(assessmentId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["saveSecondRoundResponse"],
    mutationFn: async ({
      answer,
      questionId,
      assessment_id,
      ai_question_id,
      is_cross_question
    }: {
      answer: string;
      questionId: string;
      assessment_id: string;
      ai_question_id: string;
      is_cross_question: boolean;
    }) => {
      return candidateInstance
        .post(
          `${APIEndPoints.SAVE_SECOND_ROUND_RESPONSE(
            assessmentId,
            questionId
          )}`,
          {
            answer,
            ai_question_id,
            assessment_id,
            is_cross_question
          }
        )
        .then((res) => {
          queryClient.invalidateQueries(["saveSecondRoundResponse"]);
          return res;
        });
    },
    onError: (e) => e
  });
}
