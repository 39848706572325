import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import SkillsButton from "components/Containers/Button/Button/SkillsButton";
import strings from "globals/constants/strings.json";
import {
  AssessmentData,
  AssessmentTemplateData
} from "globals/types/globalTypes";
import moment from "moment";
import React, {CSSProperties, useState} from "react";
import styles from "./AssessmentTemplateCard.module.scss";

interface Props {
  assessmentDetails: AssessmentData | AssessmentTemplateData;
  onClick: (details: AssessmentData | AssessmentTemplateData) => void;
  source: "assessment" | "assessment_template";
  cardStyle?: CSSProperties;
}

const ExtraTechnologies = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f7f7f7",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: "8px 0"
  }
}));

export const AssessmentTemplateCard: React.FC<Props> = ({
  cardStyle,
  assessmentDetails,
  source
}) => {
  const {name, skills} = assessmentDetails;
  const isAssessment = source === "assessment";

  const [confirmationAlert, setconfirmationAlert] = useState<{
    open: boolean;
    data?: number;
  }>({open: false});

  const onCloseModal = () => {
    setconfirmationAlert({open: false, data: undefined});
  };

  return (
    <div className={styles.assessment__main__card} style={cardStyle}>
      <div className={styles.assessment__card__top__row}>
        <div className={styles.assessment__card__name}>
          <p className="font-bold" style={{fontSize: 14}}>
            {name.length > 35 ? `${name.substring(0, 35)}...` : name}
          </p>
        </div>
      </div>

      {/* skills section */}
      <div className={styles.assessment__card__skills}>
        <div>
          <p
            className="font-semibold mb-4"
            style={{fontSize: 13}}
          >{`${strings.skills}`}</p>
        </div>
        <div className={styles.skills__row}>
          {skills.slice(0, isAssessment ? 2 : 3).map((skill) => (
            <div className="mr-3" id={skill.id.toString()} key={skill.id}>
              <SkillsButton skill={skill} />
            </div>
          ))}
          {(() => {
            if (
              skills.length > (isAssessment ? 1 : 2) &&
              skills.length - (isAssessment ? 2 : 3) > 0
            )
              return (
                <ExtraTechnologies
                  title={
                    <div className={`${styles.extra__skills}`}>
                      {skills.slice(isAssessment ? 2 : 3).map((skill) => (
                        <div key={skill.id} className="mx-3">
                          <SkillsButton skill={skill} />
                        </div>
                      ))}
                    </div>
                  }
                >
                  <p className="cursor-pointer" style={{fontSize: 12}}>
                    +{skills.length - (isAssessment ? 2 : 3)}
                  </p>
                </ExtraTechnologies>
              );
          })()}
        </div>
        <div
          className="mt-3 flex items-center justify-between w-full"
          style={{display: isAssessment ? "none" : "flex"}}
        >
          <p className="text-monoGrey-800" style={{fontSize: 13}}>
            Created on:{" "}
            {moment(assessmentDetails.created_at).format("D MMM YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};
