import {Checkbox} from "@mui/material";
import {useRollbar} from "@rollbar/react";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import InterviewActionButton from "components/Button/InterviewActionButton";
import {useChat} from "components/ChatProvider";
import {Row} from "components/Row";
import SystemChat from "components/SystemChat";
import {CountDownTimer} from "components/Timer";
import UserChat from "components/UserChat";
import {Webcam} from "components/Webcam";
import {newInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";
import {microCopies} from "globals/constants/microcopies";
import {
  createSecondRoundQuestion,
  postMixPanelEvent,
  sortInterviewQuestions
} from "globals/helpers";
import {
  Chat,
  RuntimeQuestion,
  SecondRoundQuestion,
  SecondRoundQuestionType
} from "globals/types/globalTypes";
import {useSaveBehaviourQuestions} from "hooks/assessment/useSaveBehaviourQuestions";
import {useSaveQuestion} from "hooks/assessment/useSaveQuestion";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {useUpdateSecondRoundResponse} from "hooks/interview/useUpdateSecondRoundResponse";
import {useUploadScreenshot} from "hooks/interview/useUploadScreenshot";
import {Images} from "Images";
import {axiosPatchModule} from "network/Patch/AxiosPatchModule";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FullScreen, FullScreenHandle} from "react-full-screen";
import {useSearchParams} from "react-router-dom";
import {io} from "socket.io-client";
import {v4 as uuid} from "uuid";
import CodingRound from "./CodingRound";
import interviewCommonStyles from "./Interview.module.scss";
import styles from "./SecondRoundInterview.module.scss";

interface IProps {
  videoConstraints: MediaTrackConstraints;
  fullScreenHandle: FullScreenHandle;
  showTimer: boolean | undefined;
  questions: SecondRoundQuestion[];
  isTestFinishing: boolean | undefined;
  handleFullScreenChange: (state: boolean, handle: any) => void;
  onPressEnd: () => void;
  stopRecording: () => void;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: Dispatch<SetStateAction<number>>;
  currentBehaviourQuestionIndex: number;
  setCurrentBehaviourQuestionIndex: Dispatch<SetStateAction<number>>;
  currentCodingQuestionIndex: number;
  setCurrentCodingQuestionIndex: Dispatch<SetStateAction<number>>;
  showTabSwitchText?: boolean;
}

const socket = io(process.env.REACT_APP_SOCKET_URL);

let recorder: MediaRecorder | null = null;

const clearRecorders = () => {
  if (recorder && recorder.state !== "inactive") {
    recorder.stop();
    recorder.stream.getTracks().forEach((track) => track.stop());
    recorder = null;
  }
};

const initRecorder = async (): Promise<MediaRecorder> => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: {echoCancellation: true, noiseSuppression: true}
  });
  const recorder = new MediaRecorder(stream, {
    mimeType: MediaRecorder.isTypeSupported("audio/mp4")
      ? "audio/mp4"
      : "audio/webm"
  });
  return recorder;
};

const label = {inputProps: {"aria-label": "Checkbox demo"}};

export const SecondRoundInterviewFlow: React.FC<IProps> = ({
  videoConstraints,
  questions,
  fullScreenHandle,
  showTimer,
  isTestFinishing,
  handleFullScreenChange,
  onPressEnd,
  stopRecording,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  currentBehaviourQuestionIndex,
  setCurrentBehaviourQuestionIndex,
  currentCodingQuestionIndex,
  setCurrentCodingQuestionIndex,
  showTabSwitchText
}) => {
  const [queryParams] = useSearchParams();
  const rollbar = useRollbar();

  const assessmentId = queryParams.get("assessment");
  const isDummy = queryParams.get("isDummy");

  const {
    answer,
    setAnswer,
    audio,
    setAudio,
    chats,
    setChats,
    greetingChats,
    setGreetingChats
  } = useChat();
  const {interview_details, getMicrocopy, siteConstants} =
    useAppCommonDataProvider();
  const {mutateAsync: updateQuestionResponse} = useUpdateSecondRoundResponse(
    assessmentId!
  );
  const {mutateAsync: syncQuestionToNode} = useSaveQuestion();
  const {mutateAsync: syncBehaviorQuestionsToNode} = useSaveBehaviourQuestions(
    assessmentId!
  );
  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {mutateAsync: uploadScreenshot} = useUploadScreenshot({
    assessmentId: assessmentId!
  });

  const [processingResponse, setProcessingResponse] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string>("");
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [allQuestions, setAllQuestions] = useState<SecondRoundQuestion[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [showPhaseOneCompletedSection, setShowPhaseOneCompletedSection] =
    useState<boolean>(false);
  const [currentQuestionType, setCurrentQuestionType] =
    useState<SecondRoundQuestionType>("Technical_analysis");
  const [technicalRoundRemainingTime, setTechnicalRoundRemainingTime] =
    useState<number>(900);
  const [behaviouralRoundRemainingTime, setBehaviouralRoundRemainingTime] =
    useState<number>(420);
  const [isInFullScreen, setIsInFullScreen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const currentQuestion = allQuestions[currentQuestionIndex];
  const createCrossQuestion = currentQuestionIndex % 2 === 0;

  const isCodingQuestion = currentQuestion
    ? currentQuestion.overall_type === "coding_analysis"
    : false;
  const isBehaviourQuestion = currentQuestion
    ? currentQuestion.overall_type === "behavioral_analysis"
    : false;
  const isTechnicalQuestion = currentQuestion
    ? currentQuestion.overall_type === "Technical_analysis"
    : false;
  const isGreetingQuestion = currentQuestion
    ? currentQuestion.overall_type === "greetings"
    : false;

  const numberOfTechnicalQuestions = Number(
    siteConstants.find(
      (constant) => constant.title === "number_of_technical_questions"
    )!.value
  );

  const {
    main_question_id: documentId,
    candidate: {
      user: {first_name: candidateFirstName, last_name: candidateLastName}
    },
    behavior_duration,
    assessment_duration,
    ai_assessment_id,
    total_tab_switches
  } = interview_details?.data.assessment!;

  const saveCrossQuestionToList = ({
    cross_question,
    cross_question_id,
    audio_file_path_mp3,
    id
  }: {
    cross_question_id: string;
    audio_file_path_mp3: string;
    cross_question: string;
    id: number;
  }) => {
    const newQuestion = createSecondRoundQuestion({
      ai_question_id: cross_question_id,
      assessment_id: +assessmentId!,
      audio_file_path_mp3,
      isFollowUpQuestion: true,
      overall_type: "Technical_analysis",
      questionText: cross_question,
      id
    });

    // Insert cross question between current and next question
    const newQuestions = [...allQuestions];
    newQuestions.splice(currentQuestionIndex + 1, 0, newQuestion);

    const updatedList = sortInterviewQuestions(newQuestions);
    // setAllQuestions(() => updatedList);
    setChats?.([
      ...chats,
      {
        chat: cross_question,
        isUserChat: false
      }
    ]);

    return updatedList;
  };

  const startAudioRecording = async () => {
    const newId = uuid();

    recorder = await initRecorder();

    recorder.start(10);
    recorder.ondataavailable = ({data}) => {
      setAudioChunks((prev) => [...prev, data]);
      socket.emit("audio_chunk", {
        audio_chunk: data,
        room_id: newId
      });
    };

    setRoomId(newId);

    setTimeout(() => {
      socket.emit("start_stream", {
        room_id: newId
      });
    }, 1000);
  };

  const stopCurrentAudio = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const saveQuestionToNode = async (
    {
      questionId,
      questionText,
      overallType,
      questionAnswer,
      audioUrl,
      behaviour_questions,
      sample_input,
      expected_output,
      skill
    }: {
      questionId?: string;
      questionText?: string;
      overallType?: SecondRoundQuestionType;
      questionAnswer?: string;
      audioUrl?: string;
      behaviour_questions?: RuntimeQuestion[];
      sample_input?: any;
      expected_output?: any;
      skill?: string;
    },
    isBehaviourQuestion?: boolean,
    isCodingQuestion?: boolean
  ) => {
    // Save new question(s) to the node backend to maintain parity amongst the
    // questions list between both node and python databases
    return !isBehaviourQuestion
      ? await syncQuestionToNode({
          assessment_id: assessmentId,
          question_id: questionId,
          question_text: questionText,
          __typname: "",
          overall_type: overallType,
          question_answer: questionAnswer,
          audio_url: audioUrl,
          sample_input: isCodingQuestion ? sample_input : null,
          expected_output: isCodingQuestion ? expected_output : null,
          skill
        })
      : await syncBehaviorQuestionsToNode({
          assessment_id: assessmentId,
          behaviour_questions: behaviour_questions?.map(
            ({questionAnswer, questionId, questionText, question_options}) => ({
              correct_answer: questionAnswer,
              question_id: questionId,
              question_text: questionText,
              overall_type: "behavioral_analysis",
              options: question_options
            })
          )
        });
  };

  const saveTranscribedResponse = async () => {
    const {ai_question_id} = currentQuestion;

    socket.emit("delete_question", {
      document_id: documentId,
      question_id: ai_question_id,
      room_id: roomId
    });

    const {
      data: {
        data: {question_response}
      }
    } = await updateQuestionResponse({
      answer: answer.trim() === "" ? "-" : answer,
      ai_question_id,
      assessment_id: ai_assessment_id,
      is_cross_question: isGreetingQuestion ? false : createCrossQuestion,
      questionId: currentQuestion.id.toString()
    });

    if (isGreetingQuestion) {
      setGreetingChats?.([
        ...greetingChats,
        {
          chat: allQuestions[currentQuestionIndex + 1].question,
          isUserChat: false
        }
      ]);
    }

    if (isTechnicalQuestion) {
      let newQuestions: SecondRoundQuestion[] = [];
      const {real_time_question} = question_response;

      if (createCrossQuestion) {
        const {audio_file_path_mp3, cross_question, cross_question_id, skill} =
          question_response;

        const {
          data: {id}
        } = await saveQuestionToNode({
          questionId: cross_question_id,
          questionText: cross_question,
          overallType: "Technical_analysis",
          questionAnswer: "",
          audioUrl: audio_file_path_mp3,
          skill
        });

        newQuestions = saveCrossQuestionToList({
          audio_file_path_mp3,
          cross_question,
          cross_question_id,
          id
        });
      } else {
        setChats?.([
          ...chats,
          {
            chat: allQuestions[currentQuestionIndex + 1].question,
            isUserChat: false
          }
        ]);
      }

      if (real_time_question && currentQuestionIndex !== 14) {
        const {audio_file_path_mp3, questionId, questionText, skill} =
          real_time_question;

        const newNodeQuestion = await saveQuestionToNode({
          questionId,
          questionText,
          overallType: "Technical_analysis",
          questionAnswer: "",
          audioUrl: audio_file_path_mp3,
          skill
        });

        const {
          data: {ai_question_id, id}
        } = newNodeQuestion;

        const newQuestion = createSecondRoundQuestion({
          ai_question_id,
          assessment_id: +assessmentId!,
          audio_file_path_mp3,
          id,
          isFollowUpQuestion: false,
          overall_type: "Technical_analysis",
          questionText
        });

        newQuestions.push(newQuestion);
      }

      if (createCrossQuestion) {
        setAllQuestions(() => newQuestions);
      }
    }
    socket.connect();

    emitStopStreamEvent();
    clearValuesAndMoveToNextQuestion();
  };

  const stopAudioRecording = async (isTemporary?: boolean) => {
    if (isTemporary) {
      stopCurrentAudio();
      clearRecorders();
      return;
    }

    socket.disconnect();
    clearRecorders();
    saveTranscribedResponse();
  };

  const emitStopStreamEvent = () => {
    socket.emit("stop_stream", {
      room_id: roomId
    });
  };

  const clearValuesAndMoveToNextQuestion = () => {
    setAudioChunks([]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setAnswer?.("");
    setRoomId(uuid());

    setTimeout(() => {
      setProcessingResponse(false);
    }, 10);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  const handleQuestionChange = async (code?: string) => {
    try {
      if (audio && !audio.paused) {
        return;
      }

      if (isTechnicalQuestion) {
        setDisabled(true);
        setProcessingResponse(true);
      }

      // Stop the audio if it is currently being played.
      // TODO: could potentially be removed since the next button has already been disabled
      // if the audio is being played.
      stopCurrentAudio();

      // Check for if we are trying to test things on the landing page
      if (isDummy) {
        return setCurrentQuestionIndex((prev) => prev + 1);
      }

      if (isBehaviourQuestion) {
        const nextQuestion = allQuestions[currentQuestionIndex + 1];
        if (!nextQuestion) {
          return stopRecording();
        } else {
          return handleBehaviorQuestionResponse();
        }
      }

      if (isCodingQuestion) {
        return handleCodingQuestionResponse(code);
      }

      // Check if it is the last technical question's follow up question
      if (currentQuestionIndex === 15) {
        emitStopStreamEvent();
        return await createBehaviourQuestions();
      }

      // Proceed normally for saving response of the techincal questions
      stopAudioRecording();
    } catch (e) {
      console.error(
        "we ran into an error when trying to go to the next question"
      );
      postMixPanelEvent("Failed to switch to next question", {
        candidateName: `${candidateFirstName}-${candidateLastName}`,
        error: e
      });
      rollbar.error("Failed to switch to next question", {
        candidateName: `${candidateFirstName}-${candidateLastName}`,
        error: e
      });
      // @ts-ignore
      throw new Error(e);
    }
  };

  const handleFullScreenEnter = () => {
    if (isInFullScreen) return;
    setIsInFullScreen(true);
  };

  const onCaptureScreenshot = (url: string) => {
    const firstName =
      interview_details?.data.assessment.candidate.user.first_name.replace(
        /[^a-zA-Z0-9]/g,
        "-"
      );
    const lastName =
      interview_details?.data.assessment.candidate.user.last_name.replace(
        /[^a-zA-Z0-9]/g,
        "-"
      );
    const fileName = `${firstName}-${lastName}-${uuid()}-interview-screenshot.png`;
    const [metadata, data] = url.split(",");
    const binaryData = atob(data);
    const byteNumbers = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const file = new File([byteNumbers], fileName, {type: "image/png"});
    const formData = new FormData();
    formData.append("file", file);
    uploadScreenshot(formData);
  };

  const createBehaviourQuestions = async () => {
    stopAudioRecording(true);
    setProcessingResponse(true);
    const data = new FormData();
    data.append("assessment_id", ai_assessment_id);
    const {
      data: {
        python_data: {new_questions}
      }
    } = await newInstance.post<{
      python_data: {new_questions: RuntimeQuestion[]};
    }>("/behavioral-compatibility", data);

    await saveQuestionToNode(
      {
        behaviour_questions: new_questions
      },
      true
    );

    await updateAssessment({
      status: 3
    });

    postMixPanelEvent("Technical round completed");
    rollbar.info("Candidate completed technical round", {
      candidateName: `${candidateFirstName}-${candidateLastName}`
    });
    socket.disconnect();
    clearRecorders();

    const resolvedQuestions: SecondRoundQuestion[] = [];
    new_questions.forEach(
      ({questionId, questionText, question_options}: RuntimeQuestion) => {
        const newQuestion = createSecondRoundQuestion({
          ai_question_id: questionId,
          assessment_id: +assessmentId!,
          isFollowUpQuestion: false,
          overall_type: "behavioral_analysis",
          questionText: questionText,
          options: question_options,
          isBehaviourQuestion: true,
          assessment_behaviour_question_options: question_options.map((e) => ({
            option: e,
            ai_question_id: questionId
          }))
        });

        resolvedQuestions.push(newQuestion);
      }
    );

    const updatedList = sortInterviewQuestions([
      ...allQuestions,
      ...resolvedQuestions
    ]);
    setAllQuestions(updatedList);
    clearValuesAndMoveToNextQuestion();
  };

  const handleCodingQuestionResponse = (code?: string) => {
    socket.disconnect();

    updateAssessment({status: 4});

    const data = new FormData();
    data.append("answer", code ?? " ");
    data.append("assessment_id", ai_assessment_id);
    data.append("questionId", currentQuestion.ai_question_id);

    newInstance.post("/coding-answers", data);

    updateQuestionResponse({
      answer: code ?? "",
      ai_question_id: currentQuestion.ai_question_id,
      assessment_id: ai_assessment_id,
      is_cross_question: false,
      questionId: currentQuestion.id.toString()
    });

    if (currentQuestionIndex + 1 === allQuestions.length) {
      stopRecording();
      return;
    }

    if (currentQuestionIndex + 1 !== allQuestions.length) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setCurrentCodingQuestionIndex((prev) => prev + 1);
    }
  };

  const fetchCodingQuestions = () => {
    const data = new FormData();
    data.append("assessment_id", ai_assessment_id);

    newInstance
      .post<{python_data: {new_questions: RuntimeQuestion[]}}>(
        "/coding-compatibility",
        data
      )
      .then(
        async ({
          data: {
            python_data: {new_questions}
          }
        }) => {
          const codingQuestions = new_questions.map(
            async ({questionId, questionText, sampleInput, expectedOutput}) => {
              const res = await saveQuestionToNode(
                {
                  questionId,
                  questionText,
                  overallType: "coding_analysis",
                  questionAnswer: "",
                  audioUrl: "",
                  sample_input: JSON.stringify(sampleInput),
                  expected_output: JSON.stringify(expectedOutput),
                  skill: ""
                },
                false,
                true
              );

              const codingQuestion = createSecondRoundQuestion({
                ai_question_id: questionId,
                assessment_id: +assessmentId!,
                isFollowUpQuestion: false,
                overall_type: "coding_analysis",
                questionText,
                isTechnicalQuestion: true,
                id: res.data.id,
                sample_input: JSON.stringify(sampleInput),
                expected_output: JSON.stringify(expectedOutput)
              });

              return codingQuestion;
            }
          );

          const resolvedCodingQuestions = await Promise.all(codingQuestions);
          const updatedList = sortInterviewQuestions([
            ...allQuestions,
            ...resolvedCodingQuestions
          ]);
          setAllQuestions(updatedList);
        }
      );
  };

  const handleBehaviorQuestionResponse = () => {
    // Fetch coding questions after submitting first response.
    if (currentBehaviourQuestionIndex === 1) {
      const hasCodingQuestions =
        allQuestions.filter((e) => e.overall_type === "coding_analysis")
          .length > 0;

      if (!hasCodingQuestions) {
        fetchCodingQuestions();
      }
    }
    socket.disconnect();
    updateAssessment({
      status: 3
    });

    axiosPatchModule(
      APIEndPoints.SAVE_BEHAVIOUR_RESPONSE(
        assessmentId!,
        currentQuestion.ai_question_id
      ),
      {
        selected_answers: selectedOptions,
        python_assessment_id: ai_assessment_id
      }
    ).finally(() => {
      setCurrentBehaviourQuestionIndex((prev) => prev + 1);
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedOptions([]);
    });

    // Check if the current question is behavioural and if next question is coding
    const nextQuestion = allQuestions[currentQuestionIndex + 1];
    if (nextQuestion.overall_type === "coding_analysis") {
      postMixPanelEvent("Behaviour round completed");
      rollbar.info("Candidate completed behaviour round", {
        candidateName: `${candidateFirstName}-${candidateLastName}`
      });
      setShowPhaseOneCompletedSection(true);
      updateAssessment({status: 4});
    }
  };

  const handleTechnicalRoundTimerChange = (remaining: number) => {
    // Instead of running redundant intervals, check if remaining time is a multiple of 10 thus runnign everything in an interval of 10s
    if (remaining % 10 === 0) {
      updateAssessment({
        assessment_duration: remaining
      });
    }

    // Check if time remaining for the technical round is 10 seconds and no behaviour questions exist for the current interview.
    // Create behaviour questions if they do exist.
    if (
      remaining === 10 &&
      allQuestions.filter(
        (question) => question.overall_type === "behavioral_analysis"
      ).length === 0
    ) {
      createBehaviourQuestions();
    }
    setTechnicalRoundRemainingTime(remaining);
  };

  useEffect(() => {
    if (!currentQuestion) {
      return;
    }

    if (currentQuestion.overall_type !== currentQuestionType) {
      setCurrentQuestionType(currentQuestion.overall_type);
    }

    if (!isInFullScreen) return;

    if (!isCodingQuestion && !isBehaviourQuestion) {
      const newAudio = new Audio(currentQuestion.audio_url);
      setAudio?.(newAudio);
      newAudio.playbackRate = 1.1;
      newAudio.play();

      newAudio.onended = () => {
        clearRecorders();
        setTimeout(() => {
          startAudioRecording();
        }, 50);
      };
    }
  }, [currentQuestion, currentQuestionIndex, isCodingQuestion, isInFullScreen]);

  useEffect(() => {
    window.addEventListener("tab_switched", () => stopAudioRecording(true));
    window.addEventListener("tab_switch_reverted", () => startAudioRecording());
    window.addEventListener("entered_full_screen", () =>
      handleFullScreenEnter()
    );

    if (questions.length > 0) {
      setAllQuestions(sortInterviewQuestions(questions));

      if (currentQuestionIndex > 2) {
        const chatList: Chat[] = [];
        for (let i = 2; i < currentQuestionIndex; i++) {
          const {question, question_answer} = questions[i];
          chatList.push({
            chat: question,
            isUserChat: false
          });
          chatList.push({
            chat: question_answer ?? "",
            isUserChat: true
          });
        }

        chatList.push({
          chat: questions[currentQuestionIndex].question,
          isUserChat: false
        });
        setChats?.(chatList);
      } else {
        setChats?.([
          ...chats,
          {
            chat: questions[2].question,
            isUserChat: false
          }
        ]);
      }
    }

    const greetingQuestions = questions.filter(
      (e) => e.overall_type === "greetings"
    );
    if (greetingQuestions.length > 0) {
      setGreetingChats?.([
        ...greetingChats,
        {chat: greetingQuestions[0].question, isUserChat: false}
      ]);
    }

    return () => {
      window.removeEventListener("tab_switched", () =>
        stopAudioRecording(true)
      );
      window.removeEventListener("tab_switch_reverted", () =>
        startAudioRecording()
      );
      window.removeEventListener("entered_full_screen", () =>
        handleFullScreenEnter()
      );
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (roomId) {
      socket.on("transcription", (data) => {
        setAnswer?.(data.transcript);
        if (data.room_id === roomId) {
          if (isGreetingQuestion) {
            if (!greetingChats[greetingChats.length - 1].isUserChat) {
              setGreetingChats?.([
                ...greetingChats,
                {
                  chat: data.transcript,
                  isUserChat: true
                }
              ]);
            } else {
              const newChats = [...greetingChats];
              newChats.pop();
              setGreetingChats?.([
                ...newChats,
                {
                  chat: data.transcript,
                  isUserChat: true
                }
              ]);
            }
          } else {
            // if (!chats[chatsLength - 1].isUserChat) {
            setChats?.([
              ...chats,
              {
                chat: data.transcript,
                isUserChat: true
              }
            ]);
            // } else {
            //   const newChats = [...chats];
            //   newChats.pop();
            //   setChats?.([
            //     ...newChats,
            //     {
            //       chat: data.transcript,
            //       isUserChat: true
            //     }
            //   ]);
            // }
          }
        }
      });
    }
  }, [roomId]);

  useEffect(() => {
    const div = document.getElementById(
      "chats-container-for-user-interaction"
    )!;
    const id = setInterval(() => {
      div.scrollTop = div.scrollHeight;
    }, 500);

    setRoomId(uuid());

    socket.on("error", function (err) {
      console.log(err);
    });
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <FullScreen
      className="fullScreenStyle"
      handle={fullScreenHandle}
      onChange={handleFullScreenChange}
    >
      {isCodingQuestion ? (
        <CodingRound
          question={currentQuestion.question}
          handleQuestionChange={handleQuestionChange}
          currentQuestionNumber={currentCodingQuestionIndex}
          sampleInput={currentQuestion.sample_input}
          sampleOutput={currentQuestion.expected_output}
          stopRecording={stopRecording}
        />
      ) : (
        <main
          className="min-h-screen max-h-screen overflow-scroll"
          style={{
            background:
              "linear-gradient(126.11deg, #ECECFF 0%, #F2E9F0 35.83%, #F7E9E7 53.81%, #F5E4EE 76.96%, #FAE5F4 100%)"
          }}
        >
          <header
            className={`${interviewCommonStyles.behavior__header} border-b border-b-[#cacaca] px-6 h-32`}
          >
            <Row classNames="pt-4">
              <div className="w-2/5">
                <img
                  src={
                    "https://images.ctfassets.net/7oc31naqqojs/19IuRVsEWksBeu3EtMHkuL/86ca2c966980d64c0c4869aa03f1a312/Frame_1686552542.png"
                  }
                  alt="Logo"
                  style={{width: 235, height: 60}}
                />
              </div>
              <p className="w-2/5 font-bold text-4xl">
                {getMicrocopy(
                  isBehaviourQuestion
                    ? microCopies.INTERVIEW_ROUND_TWO_BEHAVIOURAL_ROUND_HEADING
                    : microCopies.INTERVIEW_ROUND_TWO_HEADING
                )}
              </p>
              <Row classNames="items-end w-1/5 flex-col">
                {isTechnicalQuestion && !showPhaseOneCompletedSection && (
                  <div
                    className={`py-2 px-4 rounded-[45px] text-white-900 ${
                      technicalRoundRemainingTime > 180
                        ? "bg-[#28af3a]"
                        : "bg-[#f63333]"
                    }`}
                  >
                    <CountDownTimer
                      time={assessment_duration > 0 ? assessment_duration : 900}
                      returnUpdatedValue={handleTechnicalRoundTimerChange}
                      sx={{fontWeight: "bold"}}
                      onStopTimer={() => {
                        const a = allQuestions.findIndex(
                          (e) => e.overall_type === "behavioral_analysis"
                        );
                        setCurrentQuestionIndex(a);
                      }}
                    />
                  </div>
                )}

                {currentQuestionType === "behavioral_analysis" &&
                  !showPhaseOneCompletedSection && (
                    <div
                      className={`py-2 px-4 rounded-[45px] text-white-900 ${
                        behaviouralRoundRemainingTime > 180
                          ? "bg-[#28af3a]"
                          : "bg-[#f63333]"
                      }`}
                    >
                      <CountDownTimer
                        time={behavior_duration ?? 420}
                        returnUpdatedValue={(reamaining) => {
                          if (reamaining % 10 === 0) {
                            updateAssessment({behavior_duration: reamaining});
                          }
                          setBehaviouralRoundRemainingTime(reamaining);
                        }}
                        sx={{fontWeight: "bold"}}
                        onStopTimer={() => {
                          const a = allQuestions.findIndex(
                            (e) => e.overall_type === "coding_analysis"
                          );
                          setCurrentQuestionIndex(a);
                        }}
                      />
                    </div>
                  )}

                {((total_tab_switches && total_tab_switches > 0) ||
                  showTabSwitchText) && (
                  <p className="text-center text-[16.5px] font-bold mt-3 bg-pink-600 text-white-200 p-2 rounded-xl">{`You have ${
                    5 - (total_tab_switches || 1)
                  } tab switches remaining`}</p>
                )}
              </Row>
            </Row>
          </header>

          {currentQuestionType === "Technical_analysis" && (
            <Row classNames="justify-end mt-2 px-6 font-bold">{`Question - ${
              currentQuestionIndex + 1 - 2
            }/${numberOfTechnicalQuestions * 2}`}</Row>
          )}

          <div
            className={"flex mt-4 px-6"}
            style={{height: "calc(100vh - 10rem)"}}
          >
            <div className={`${styles.container}`}>
              <Row classNames={styles.wrapper}>
                <div className="w-full h-full flex">
                  <Row classNames={styles.own__feed}>
                    <section className="webcam__container relative w-full h-full">
                      <div className="min-h-full flex flex-col">
                        <Row
                          style={{
                            minHeight: +videoConstraints.height! / 1.2,
                            boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                            position: "relative"
                          }}
                          classNames="bg-white-900 rounded-lg flex justify-center"
                        >
                          <div className={styles.logo_dynamic_bg} />
                          <img
                            src={Images.RecruitbaeLogoWithoutText}
                            alt="logo-without-text"
                            style={{
                              zIndex: 2
                            }}
                            className={`${styles.scalingImage} ${
                              !audio?.paused && styles.scalingImage_animation
                            }`}
                          />
                        </Row>

                        <div
                          className="relative mt-20 bg-blue-200"
                          style={{
                            width: "100%"
                          }}
                        >
                          <Webcam
                            className="rounded-lg"
                            pingedScreenshots={
                              isTechnicalQuestion || isBehaviourQuestion
                            }
                            pingInterval={30000}
                            onCaptureScreenshot={onCaptureScreenshot}
                          />
                        </div>
                      </div>
                    </section>
                  </Row>

                  {!showTimer && (
                    <div className="flex-0.6 h-full">
                      <div className={styles.questions__card}>
                        <div
                          className="rounded-lg pt-[18px] px-[24px] min-w-[97%] max-w-[97%] h-full relative bg-white-900 overflow-scroll"
                          id="chats-container-for-user-interaction"
                          style={{
                            boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                            display: showPhaseOneCompletedSection
                              ? "flex"
                              : "block",
                            alignItems: showPhaseOneCompletedSection
                              ? "center"
                              : "flex-start",
                            justifyContent: showPhaseOneCompletedSection
                              ? "center"
                              : "flex-start"
                          }}
                        >
                          {showPhaseOneCompletedSection ? (
                            <section className="w-full flex items-center flex-col">
                              <p className="text-center font-bold text-xl">
                                {getMicrocopy(
                                  microCopies.INTERVIEW_PHASE1_COMPLETED_TITLE
                                )}
                              </p>
                              <p className="w-3/4 text-center mt-4 text-sm font-medium">
                                <span className="text-green-400">
                                  Congrats!{" "}
                                </span>
                                {getMicrocopy(
                                  microCopies.INTERVIEW_PHASE1_COMPLETED_MESSAGE
                                )}
                              </p>
                              <button
                                onClick={() => {
                                  setShowPhaseOneCompletedSection(false);
                                  setCurrentQuestionIndex((prev) => prev + 1);
                                }}
                                className="mt-6 bg-lightBlack-900 text-white-900 py-2 px-6 rounded-lg font-bold"
                              >
                                Continue to the coding exercise
                              </button>
                            </section>
                          ) : isGreetingQuestion ? (
                            greetingChats.map(({chat, isUserChat}, index) => (
                              <div className="relative w-full mb-6">
                                <Row
                                  key={`greeting-chat-${index}`}
                                  classNames={`${
                                    isUserChat ? "flex-row-reverse" : "flex-row"
                                  }`}
                                >
                                  {isUserChat ? (
                                    <Row
                                      classNames="flex-row-reverse w-full !items-start"
                                      key={`greeting-user-chat-${index + 1}`}
                                    >
                                      <div
                                        className="rounded-full flex items-center justify-center bg-[#785DD2] text-white-900 font-bold p-3"
                                        style={{width: 20, height: 20}}
                                      >
                                        {interview_details?.data.assessment.candidate.user.first_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </div>
                                      <UserChat
                                        chat={chat}
                                        index={index}
                                        audioChunks={audioChunks}
                                        onEndTime={handleQuestionChange}
                                        isGreetingQuestion
                                      />
                                    </Row>
                                  ) : (
                                    <Row
                                      classNames="w-full"
                                      key={`system-chat-${index + 1}`}
                                    >
                                      <div className="bg-[#FBE8FF] p-2 rounded-[50%]">
                                        <img
                                          src={Images.RecruitbaeLogoWithoutText}
                                          alt=""
                                          style={{width: 20, height: 20}}
                                        />
                                      </div>
                                      <SystemChat chat={chat} />
                                    </Row>
                                  )}
                                </Row>
                              </div>
                            ))
                          ) : !isBehaviourQuestion ? (
                            chats.map(({chat, isUserChat}, index) => (
                              <div className="relative w-full mb-6">
                                <Row
                                  key={`chat-${index}`}
                                  classNames={`${
                                    isUserChat ? "flex-row-reverse" : "flex-row"
                                  }`}
                                >
                                  {isUserChat ? (
                                    <Row
                                      classNames="flex-row-reverse w-full !items-start"
                                      key={`user-chat-${index + 1}`}
                                    >
                                      <div
                                        className="rounded-full flex items-center justify-center bg-[#785DD2] text-white-900 font-bold p-3"
                                        style={{width: 20, height: 20}}
                                      >
                                        {interview_details?.data.assessment.candidate.user.first_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </div>
                                      <UserChat
                                        chat={chat}
                                        index={index}
                                        audioChunks={audioChunks}
                                        onEndTime={handleQuestionChange}
                                      />
                                    </Row>
                                  ) : (
                                    <Row
                                      classNames="w-full"
                                      key={`system-chat-${index + 1}`}
                                    >
                                      <div className="bg-[#FBE8FF] p-2 rounded-[50%]">
                                        <img
                                          src={Images.RecruitbaeLogoWithoutText}
                                          alt=""
                                          style={{width: 20, height: 20}}
                                        />
                                      </div>
                                      <SystemChat chat={chat} />
                                    </Row>
                                  )}
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div
                              className={`flex flex-col justify-center h-[65vh] xxl:!pr-[60px] ${styles.frame_div}`}
                            >
                              <div>
                                <p className="flex gap-[6px] text-18 font-semibold leading-[21px]">
                                  <span>{currentBehaviourQuestionIndex}.</span>{" "}
                                  {currentQuestion.question}
                                </p>
                                <div className="mt-7 flex flex-col gap-5">
                                  <p className="text-14 font-medium -mb-2">
                                    Multiple options can be selected
                                  </p>
                                  {currentQuestion.assessment_behaviour_question_options &&
                                    currentQuestion.assessment_behaviour_question_options.map(
                                      (opt: any) => (
                                        <div className="flex p-[10px] gap-[10px] rounded border border-[#FFD8F4] bg-[#e087c71a] mr-7 xxl:mr-[54px]">
                                          <Checkbox
                                            {...label}
                                            checked={selectedOptions.includes(
                                              opt.option
                                            )}
                                            onClick={() =>
                                              setSelectedOptions(
                                                (prevSelectedOptions) =>
                                                  prevSelectedOptions.includes(
                                                    opt.option
                                                  )
                                                    ? prevSelectedOptions.filter(
                                                        (option) =>
                                                          option !== opt.option
                                                      )
                                                    : [
                                                        ...prevSelectedOptions,
                                                        opt.option
                                                      ]
                                              )
                                            }
                                            sx={{
                                              color: "#00000033",
                                              width: "17px",
                                              height: "17px",
                                              borderRadius: "2px",
                                              background: "#fff",
                                              "&.Mui-checked": {
                                                background: "#8433A8",
                                                color: "#E6D6EE"
                                              }
                                            }}
                                          />
                                          <p className="text-16 font-medium leading-[19px] ">
                                            {opt.option}
                                          </p>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="h-10% flex items-end justify-end">
                        {!showPhaseOneCompletedSection && (
                          <InterviewActionButton
                            isDarkMode
                            label=" Next Question"
                            handleClick={() => handleQuestionChange("")}
                            disabled={
                              (!isCodingQuestion &&
                                !isBehaviourQuestion &&
                                audio &&
                                !audio.paused) ||
                              disabled ||
                              processingResponse
                            }
                            isLoading={processingResponse}
                            otherStyles={`${
                              (!isCodingQuestion &&
                                !isBehaviourQuestion &&
                                audio &&
                                !audio.paused) ||
                              disabled ||
                              processingResponse
                                ? "disabled cursor-not-allowed"
                                : ""
                            }`}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </main>
      )}
    </FullScreen>
  );
};
