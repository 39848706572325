import React, {useEffect, useState} from "react";
import {Bounce} from "react-activity";
import {useSearchParams} from "react-router-dom";
import {AssessmentData} from "./AssessmentData";
import {AssessmentListAndBasicDetails} from "./AssessmentListAndBasicDetails";
import styles from "./CandidateDetails.module.scss";
import NoDetailsComp from "./NoDetails/NoDetailsComp";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Loader from "components/Loader";
import SizedBox from "components/SizedBox/SizedBox";

import {AppColors} from "globals/appcolors";
import {getAssessmentDetails} from "globals/helpers";
import {CandidateDetails} from "globals/types/APIResponses";
import {
  CandidateAssesments,
  CandidateDetailsData
} from "globals/types/globalTypes";

import {useCandidateDetails} from "hooks/candidate/useCandidateDetails";

export enum ModalType {
  UPDATE,
  DELETE,
  NONE
}

export type Confirmation = {
  show: boolean;
  type?: ModalType;
  data?: string | object | null;
};

const CandidateDetail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const candidateId = searchParams.get("candidate");

  //STATE DEFINITIONS
  const [isFetching, setisFetching] = useState<boolean>(true);
  const [candidateDetails, setcandidateDetails] =
    useState<CandidateDetailsData>();
  const [candidateAssessments, setCandidateAssessments] = useState<
    CandidateAssesments[]
  >([]);
  const [currentAssessmentId, setCurrentAssessmentId] = useState<number>(
    Number.NaN
  );
  const [candidateAssessmentDetails, setCandidateAssessmentDetails] = useState<
    CandidateDetails | null | undefined
  >(undefined);
  const [showConfirmation, setShowConfirmation] = useState<Confirmation>({
    show: false
  });

  /* CUSTOM HOOK CALLS */
  const {refetch: getCandidateDetailsById} = useCandidateDetails(candidateId!);
  const {loading, setLoading} = useAppCommonDataProvider();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCandidateDetails();
  }, []);

  useEffect(() => {
    const assessmentId = searchParams.get("assessment");
    if (assessmentId !== null) {
      setCurrentAssessmentId(+assessmentId);
      fetchAssessmentDetails(assessmentId);
    }
  }, [searchParams]);

  function fetchCandidateDetails() {
    getCandidateDetailsById().then((res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          const {assessments} = res.data.data;
          setisFetching(false);
          setcandidateDetails(res.data.data);

          const sortedAssessments = assessments.reverse();
          setSearchParams({
            candidate: candidateId!,
            assessment: sortedAssessments[0].id.toString()
          });
          setCandidateAssessments(sortedAssessments);
        }
      }
    });
  }

  async function fetchAssessmentDetails(id: string) {
    setLoading?.(true);
    getAssessmentDetails(id)
      .then((res) => {
        if (res?.data !== undefined || res?.data !== null) {
          setCandidateAssessmentDetails(res);
        }
      })
      .finally(() => setLoading?.(false));
  }

  // const deleteThisCandidate = async () => {
  //   axiosDeleteModule(APIEndPoints.CANDIDATE_DELETE(userObjectId!))
  //     .then((res) => {
  //       eventTracker("Candidate Deleted", "A candidate was deleted");
  //       toast.success(strings.candidate.successMsgs.delete);
  //       navigate(-1);
  //     })
  //     .catch((err) => {
  //       toast.error(strings.candidate.errorMsgs.general);
  //       console.log(err);
  //     });
  // };

  const onCloseConfirmationModal = () =>
    setShowConfirmation((preV) => ({...preV, show: false, data: null}));

  // const notifyCandidate = async (e: React.MouseEvent<HTMLButtonElement>) => {
  //   setSendingMailToCandidate(true);
  //   e.preventDefault();
  //   await axiosPostModule(
  //     APIEndPoints.NOTIFY_RESULT(
  //       candidateDetails!.id,
  //       assessmentListOfCandidate[selectedAssesIndex].id
  //     )
  //   )
  //     .then((res: { message: string }) => {
  //       if (res.message) {
  //         toast.success(res.message);
  //         setTimeout(() => {
  //           setSendingMailToCandidate(false);
  //         }, 500);
  //       }
  //     })
  //     .catch((err) => {
  //       setSendingMailToCandidate(false);
  //     });
  // };

  const assessmentList =
    candidateAssessments.length > 0
      ? candidateAssessments.map((assessment, index) => ({
          label: assessment.name,
          value: assessment.id
        }))
      : [];

  if (isFetching) return <Loader />;

  return (
    <main className="my-4">
      <section className={styles.container}>
        <AssessmentListAndBasicDetails
          candidateId={candidateId!}
          assessmentList={assessmentList}
          candidateDetails={candidateDetails}
          currentAssessmentId={currentAssessmentId}
          setCurrentAssessmentId={setCurrentAssessmentId}
          setSearchParams={setSearchParams}
        />

        <SizedBox height="30px" />

        {loading === true ? (
          <div
            className="flex justify-center items-center"
            style={{minHeight: "20vh"}}
          >
            <Bounce size={30} color={AppColors.PRIMARYCOLOR} />
          </div>
        ) : (
          candidateAssessmentDetails !== undefined &&
          (candidateAssessmentDetails === null ? (
            <p>could not get data</p>
          ) : candidateAssessmentDetails.data.assessment.status >= 3 ? (
            <AssessmentData
              assessmentDetails={candidateAssessmentDetails}
              candidateDetails={candidateDetails}
              fetchAssessmentDetails={fetchAssessmentDetails}
            />
          ) : (
            <NoDetailsComp />
          ))
        )}
      </section>

      <ConfirmationModal
        open={showConfirmation.show}
        onCloseModal={onCloseConfirmationModal}
        heading={`Are you sure you want to ${
          showConfirmation.type === ModalType.DELETE ? "delete" : "update"
        }?`}
        description={`${
          showConfirmation.type === ModalType.DELETE
            ? "You won't be able to restore back!"
            : ""
        }`}
        // onYes={
        //   showConfirmation.type === ModalType.DELETE
        //     ? deleteThisCandidate
        //     : updateStatus
        // }
      />
    </main>
  );
};

export default CandidateDetail;
